import dayjs from "dayjs"

import { Reservation } from "../../../../../redux/api/roomReservations/types"

export const isReservationOverlapping = (
  event1: Reservation,
  event2: Reservation,
): boolean =>
  dayjs(event1.start).isBefore(dayjs(event2.end)) &&
  dayjs(event1.end).isAfter(dayjs(event2.start))

export const calculateOverlapCount = (
  reservation: Reservation,
  reservations: Reservation[],
): number =>
  reservations.reduce(
    (count, otherEvent) =>
      count + (isReservationOverlapping(reservation, otherEvent) ? 1 : 0),
    0,
  )
