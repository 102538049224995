import {
  IdAndName,
  ObjectToUnion,
  UserProfile as Profile,
} from "../../../types/sharedTypes"
import { PaginatedOptions } from "../../types"
import { NotificationSetting } from "../notifications/types"

export type UserDepartment = {
  id: string
  name: string
  capacity_limit?: number
  active?: boolean
}

export type UserResponse = {
  email: string
  first_name?: string
  last_name?: string
  phone?: string
  building_id?: string
  last_login?: string
  groups: string[]
  departments: UserDepartment[]
  building: IdAndName | null
  license?: License | null
  profile: UserProfile | null
}

export type UserProfile = Profile

export type License = {
  subscription_id: string
  assigned_at?: string
  expires_at?: string
}

export interface UserProfileRequest extends Omit<UserProfile, "picture"> {
  picture_id?: string | null
}

export type UserRequest = {
  first_name: string
  last_name: string
  email?: string
  phone?: string
  building_id?: string
  groups?: string[]
  departments?: string[]
  profile?: UserProfileRequest
  notifications?: NotificationSetting[]
}

export interface UserRequestWithEmail extends Partial<UserRequest> {
  email: string
}

export interface PatchUserRequest extends Partial<UserRequest> {
  email: string
}

export type CSVEntry = {
  email?: string
  first_name?: string
  last_name?: string
}

export type UsersResponse = {
  offset: number
  count: number
  search: string
  results: UserResponse[]
}

export type UserConfigAction = {
  type: string
  payload: string | UsersResponse | UserResponse | UserResponse[]
}

export type FetchOptions = PaginatedOptions & {
  search?: string
  role_id?: string
  department_id?: string
  license?: LicenseAssignment
}

export const LICENSE_ASSIGNMENT = {
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned",
} as const

export type LicenseAssignment = ObjectToUnion<typeof LICENSE_ASSIGNMENT>

export const isLicenseFilter = (value: string): value is LicenseAssignment =>
  Object.values(LICENSE_ASSIGNMENT).includes(value as any)
