import React from "react"

import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { ROOMS_PATHS } from "../../../Settings/Rooms/constants"

import { useAppSelector } from "../../../../redux/reducers"
import { selectUser } from "../../../../redux/user/selectors"
import {
  isNormalUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../../../redux/user/utils"

import NoDataFound from "../../../../components/NoDataFound"

import RoomSVG from "../../../../assets/images/icons/Room.svg"

import "./CalendarNoData.sass"

type Props = {
  isFilterApplied: boolean
}

const CalendarNoData = ({ isFilterApplied }: Props) => {
  const { entry: currentUser } = useAppSelector(selectUser)

  const isAdmin = isPortalAdmin(currentUser)
  const isManager = !isAdmin && isOfficeManager(currentUser)
  const isUser = isNormalUser(currentUser)

  const { t } = useTranslation()

  if ((isAdmin || isManager) && !isFilterApplied) {
    return (
      <>
        <NoDataFound>
          <RoomSVG />
          <div>
            {t(
              "desktop.manage.room_booking.calendar.no_data.admin_no_rooms.title",
            )}
          </div>
        </NoDataFound>
        <NoDataFound warning>
          <Trans
            i18nKey="desktop.manage.room_booking.calendar.no_data.admin_no_rooms.message"
            components={{ a: <Link to={ROOMS_PATHS.rooms} /> }}
          />
        </NoDataFound>
      </>
    )
  }

  if (isUser && !isFilterApplied) {
    return (
      <>
        <NoDataFound>
          <RoomSVG />
          <div>
            {t(
              "desktop.manage.room_booking.calendar.no_data.user_no_rooms.title",
            )}
          </div>
        </NoDataFound>
        <NoDataFound warning>
          {t(
            "desktop.manage.room_booking.calendar.no_data.user_no_rooms.message",
          )}
        </NoDataFound>
      </>
    )
  }

  return (
    <NoDataFound>
      <RoomSVG />
      <div>
        {t(
          "desktop.manage.room_booking.calendar.no_data.no_rooms_match_filters.title",
        )}
      </div>
    </NoDataFound>
  )
}

export default CalendarNoData
