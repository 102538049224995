import React, { useEffect, useMemo, useRef } from "react"

import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { OptionType } from "../../types/sharedTypes"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { useFetchRoomsRbQuery } from "../../redux/api/roomBookingRooms"
import { RoomResponse } from "../../redux/api/rooms/types"

type Props = {
  value: string
  onChange: (v: string) => void
  building?: string
}

export default function RoomFilter({ value, onChange, building }: Props) {
  const { data: { results: rooms = [] } = {}, isSuccess: areRoomsLoaded } =
    useFetchRoomsRbQuery({
      building,
      limit: FETCH_WITH_NO_LIMIT,
    })

  const initValueRef = useRef<string>(value)

  useEffect(() => {
    if (!areRoomsLoaded) {
      return
    }
    // Handle if building deletes or if not selected
    const initValue = initValueRef.current

    if (
      initValue !== FilterSpecialValues.EMPTY &&
      initValue !== FilterSpecialValues.ALL
    ) {
      const hasRoom =
        rooms.find((b: RoomResponse) => b.key === initValue) !== undefined

      if (!hasRoom && rooms.length > 0) {
        onChange(rooms[0].key)
        initValueRef.current = rooms[0].key
      }
    } else {
      if (initValue === FilterSpecialValues.EMPTY && rooms.length > 0) {
        onChange(rooms[0].key)
        initValueRef.current = rooms[0].key
      }
    }
  }, [rooms, areRoomsLoaded])

  const roomOptions: OptionType<string>[] = rooms.map((b: RoomResponse) => ({
    value: b.key,
    label: b.name,
  }))

  return (
    <Dropdown
      className="room-filter"
      options={roomOptions}
      value={value}
      onChange={onChange}
    />
  )
}
