import { useEffect, useMemo, useState } from "react"

import dayjs from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import { timeZone } from "../../../dayjs"
import { useNavigation } from "../../../hooks/useNavigation"
import { useToast } from "../../../hooks/useToast"
import { OptionType, RecurringType } from "../../../types/sharedTypes"
import { getOption, setTimeToDayjs, toInternalTime } from "../../../utils"
import { ROOM_BOOKING_PATHS } from "./constants"

import { useFetchRoomsRbQuery } from "../../../redux/api/roomBookingRooms"
import { RoomResponse } from "../../../redux/api/roomBookingRooms/types"
import {
  useAddRoomReservationMutation,
  useDeleteRoomReservationMutation,
  useUpdateRoomReservationMutation,
} from "../../../redux/api/roomReservations"
import {
  Person,
  ReservationVisibility,
  RoomReservationEditRequest,
  RoomReservationRequest,
  RoomReservationResponse,
} from "../../../redux/api/roomReservations/types"
import { isApiResponseError, isRejected } from "../../../redux/api/types"
import { useFetchUsersQuery } from "../../../redux/api/users"
import { UserResponse } from "../../../redux/api/users/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"

import { DatePicker } from "../../../components/advanced/DatePicker"
import Checkbox from "../../../components/basic/Checkbox"
import { Input } from "../../../components/basic/Input"
import Loader from "../../../components/basic/Loader"
import { Select } from "../../../components/basic/Select"
import {
  TimeRange,
  TimeRangePicker,
} from "../../../components/basic/TimeRangePicker"
import Field, { UncontrolledField } from "../../../components/Field"
import { setErrors } from "../../../components/Form/formUtils"
import { TIMEZONE_OPTIONS } from "../../../components/Form/options"
import PageForm from "../../../components/Form/PageFormHook"

import "./RoomReservationForm.sass"

type RoomReservationFormProps = {
  reservation?: RoomReservationResponse
}

type FormValues = {
  title: string
  room_id: OptionType<number>
  time: TimeRange
  tz: OptionType
  organizer_email: OptionType
  is_all_day: boolean
  visibility: ReservationVisibility
  attendees: OptionType[]
  description?: string
  conference_link_id?: string
  send_notification_to_attendees: boolean
  send_notification_to_organizer: boolean
  recurring?: OptionType<RecurringType>
}

const FORM_MAPPING = {
  start: "time",
  end: "time",
} as const

const validateEmail = (inputValue: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(inputValue)
}

const isValidExternalAttendee = (
  attendee: Person,
): attendee is Person & { external: { email: string } } =>
  !!attendee.external?.email

const generateOrganizerOptions = (
  users: UserResponse[],
  isAdminOrManager: boolean,
  myEmail: string,
  myEmailLabel: string,
) => {
  const organizerOptions = isAdminOrManager
    ? users
        .filter((user) => user.email !== myEmail)
        .map((user) => ({
          value: user.email,
          label: `${user.first_name} ${user.last_name}`,
        }))
    : []

  organizerOptions.unshift({
    value: myEmail,
    label: myEmailLabel,
  })

  return organizerOptions
}

const generateRoomOptions = (rooms: RoomResponse[]) => {
  return rooms.map((room) => ({
    value: room.id,
    label: room.name,
  }))
}

const generateAttendeesOptions = (
  users: UserResponse[],
  myEmail: string,
  myEmailLabel: string,
) => {
  const attendeeOptions = users
    .filter((user) => user.email !== myEmail)
    .map((user) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.email,
    }))

  attendeeOptions.unshift({
    label: myEmailLabel,
    value: myEmail,
  })

  return attendeeOptions
}

const RoomReservationForm = ({ reservation }: RoomReservationFormProps) => {
  const { errorToast, infoToast } = useToast()
  const { t } = useTranslation()
  const { push } = useNavigation()

  const { entry: user } = useAppSelector(selectUser)

  const isAdminOrManager = isOfficeManager(user) || isPortalAdmin(user)

  const myEmail = user.email

  const {
    data: { results: usersInCompany = [] } = {},
    isLoading: areUsersLoading,
  } = useFetchUsersQuery({
    limit: FETCH_WITH_NO_LIMIT,
  })

  const { data: { results: rooms = [] } = {}, isLoading: areRoomsLoading } =
    useFetchRoomsRbQuery({
      limit: FETCH_WITH_NO_LIMIT,
    })

  const ORGANIZER_OPTIONS = generateOrganizerOptions(
    usersInCompany,
    isAdminOrManager,
    myEmail,
    t("desktop.manage.room_booking.form.me"),
  )
  const ROOM_OPTIONS = generateRoomOptions(rooms)

  const ATTENDEES_OPTIONS = generateAttendeesOptions(
    usersInCompany,
    myEmail,
    t("desktop.manage.room_booking.form.me"),
  )

  const [date, setDate] = useState(dayjs(reservation?.start ?? undefined))
  const [isAllDay, setIsAllDay] = useState(reservation?.is_all_day || false)
  const [recurringUntil, setRecurringUntil] = useState(
    dayjs(reservation?.start ?? undefined),
  )

  const SCHEDULE_OPTIONS = useMemo(() => {
    let options: OptionType<RecurringType | undefined>[] = [
      { label: t("general.repeat.once"), value: undefined },
    ]
    options = options.concat(
      Object.values(RecurringType).map((value) => {
        let label = t(`general.repeat.${value}`)
        if (value === RecurringType.EVERY_DAY_OF_WEEK) {
          label = t("general.repeat.every_day_of_week", {
            day: dayjs(date).format("dddd"),
          })
        }
        return {
          value,
          label,
        }
      }),
    )

    return options
  }, [t, date])

  const methods = useForm<FormValues>({
    defaultValues: {
      title: reservation?.title || "",
      room_id: getOption<number>(ROOM_OPTIONS, reservation?.room?.id),
      time: {
        start: toInternalTime(dayjs(reservation?.start || undefined)),
        end: toInternalTime(
          dayjs(reservation?.end || dayjs().add(30, "minute")),
        ),
      },
      tz: getOption(TIMEZONE_OPTIONS, reservation?.tz || timeZone),
      organizer_email: getOption(
        ORGANIZER_OPTIONS,
        reservation?.organizer?.user?.email,
      ),
      is_all_day: reservation?.is_all_day || false,
      visibility: reservation?.visibility || ReservationVisibility.PUBLIC,
      attendees: [],
      description: reservation?.description || "",
      conference_link_id: reservation?.conference_link_id || "",
      recurring: SCHEDULE_OPTIONS[0],
      send_notification_to_attendees:
        reservation?.send_notification_to_attendees || true,
      send_notification_to_organizer:
        reservation?.send_notification_to_organizer || true,
    },
  })

  const { control, watch, setError, setValue } = methods

  useEffect(() => {
    const option = getOption(ROOM_OPTIONS, reservation?.room?.id)
    if (!option) {
      return
    }

    setValue("room_id", option)
  }, [ROOM_OPTIONS])

  useEffect(() => {
    const organizerOption = getOption(
      ORGANIZER_OPTIONS,
      reservation?.organizer?.user?.email,
    )
    if (!organizerOption) {
      return
    }
    setValue("organizer_email", organizerOption)
  }, [ORGANIZER_OPTIONS])

  useEffect(() => {
    const externalAttendees =
      reservation?.attendees
        ?.filter(isValidExternalAttendee)
        .map((attendee) => ({
          label: attendee.external.email,
          value: attendee.external.email,
        })) || []

    const attendeesOptions = reservation?.attendees
      ?.filter((attendee) => attendee.user)
      .map((attendee) =>
        getOption(ATTENDEES_OPTIONS, attendee?.user?.email || ""),
      )

    attendeesOptions?.push(...externalAttendees)

    if (!attendeesOptions) {
      return
    }
    setValue(
      "attendees",
      attendeesOptions.filter((option) => !!option),
    )
  })

  const [createRoomReservation] = useAddRoomReservationMutation()
  const [updateRoomReservation] = useUpdateRoomReservationMutation()
  const [deleteRoomReservation] = useDeleteRoomReservationMutation()

  const onUpdateClick = async (values: FormValues) => {
    if (!reservation?.id) {
      return
    }

    const tz = values?.tz?.value || timeZone
    const dateStr = dayjs(date ?? "").format("YYYY-MM-DDThh:mm")

    const data: RoomReservationEditRequest = {
      id: reservation?.id,
      title: values?.title,
      room_id: values?.room_id?.value,
      start: setTimeToDayjs(
        dayjs.tz(dateStr, tz),
        isAllDay ? "00:00" : values?.time?.start || "00:00",
      ).format(),
      end: setTimeToDayjs(
        dayjs.tz(dateStr, tz).add(isAllDay ? 1 : 0, "day"),
        isAllDay ? "00:00" : values?.time?.end || "00:00",
      ).format(),
      tz: tz,
      organizer_email: values?.organizer_email?.value,
      is_all_day: isAllDay,
      visibility: values?.visibility,
      attendees: values?.attendees?.map((attendee) => attendee?.value) || [],
      description: values?.description,
      conference_link_id: values?.conference_link_id || null,
      send_notification_to_attendees: values?.send_notification_to_attendees,
      send_notification_to_organizer: values?.send_notification_to_organizer,
    }

    const response = await updateRoomReservation(data)

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        return
      }
    }

    infoToast(t("desktop.manage.room_booking.form.update_toast"))
    push(ROOM_BOOKING_PATHS.root)
  }

  const onCreateClick = async (values: FormValues) => {
    const tz = values?.tz?.value || timeZone
    const dateStr = dayjs(date ?? "").format("YYYY-MM-DDThh:mm")

    const data: RoomReservationRequest = {
      title: values?.title,
      room_id: values?.room_id?.value,
      start: setTimeToDayjs(
        dayjs.tz(dateStr, tz),
        isAllDay ? "00:00" : values?.time?.start || "00:00",
      ).format(),
      end: setTimeToDayjs(
        dayjs.tz(dateStr, tz).add(isAllDay ? 1 : 0, "day"),
        isAllDay ? "00:00" : values?.time?.end || "00:00",
      ).format(),
      tz: tz,
      organizer_email: values?.organizer_email?.value,
      is_all_day: isAllDay,
      visibility: values?.visibility,
      attendees: values?.attendees?.map((attendee) => attendee?.value) || [],
      description: values?.description,
      conference_link_id: values?.conference_link_id || null,
      send_notification_to_attendees: values?.send_notification_to_attendees,
      send_notification_to_organizer: values?.send_notification_to_organizer,
    }

    data.recurring = values.recurring?.value
      ? {
          freq: values.recurring.value,
          until: dayjs(recurringUntil).toISOString(),
        }
      : null

    const response = await createRoomReservation(data)

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        return
      }
    }

    infoToast(t("desktop.manage.room_booking.form.create_toast"))
    push(ROOM_BOOKING_PATHS.root)
  }

  const onDeleteClick = async () => {
    if (!reservation?.id) {
      return
    }

    const response = await deleteRoomReservation(reservation.id)

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        errorToast(error.message)
        return
      }
    }

    infoToast(t("desktop.manage.room_booking.form.delete_toast"))
    push(ROOM_BOOKING_PATHS.root)
  }

  return (
    <>
      {areUsersLoading || areRoomsLoading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <PageForm
            className="RoomReservationForm"
            updateMode={!!reservation}
            onUpdate={onUpdateClick}
            onCreate={onCreateClick}
            onDelete={onDeleteClick}
            backUrl={ROOM_BOOKING_PATHS.root}
          >
            <Field
              control={control}
              name="title"
              label={t("desktop.manage.room_booking.form.title.label")}
            >
              {({ ...props }) => (
                <Input
                  {...props}
                  placeholder={t(
                    "desktop.manage.room_booking.form.title.placeholder",
                  )}
                />
              )}
            </Field>
            <Field
              control={control}
              name="organizer_email"
              label={t("desktop.manage.room_booking.form.organizer.label")}
              className="field-width-50"
            >
              {({ ...props }) => (
                <Select
                  {...props}
                  options={ORGANIZER_OPTIONS}
                  clearable
                  placeholder={t(
                    "desktop.manage.room_booking.form.organizer.placeholder",
                  )}
                />
              )}
            </Field>
            <Field
              control={control}
              name="room_id"
              label={t("desktop.manage.room_booking.form.room_id.label")}
              className="field-width-50"
            >
              {({ ...props }) => (
                <Select
                  {...props}
                  options={ROOM_OPTIONS}
                  clearable
                  placeholder={t(
                    "desktop.manage.room_booking.form.room_id.placeholder",
                  )}
                />
              )}
            </Field>
            <UncontrolledField
              className="field-width-50"
              label={t("desktop.manage.room_booking.form.date.label")}
            >
              <DatePicker
                minDate={dayjs().toDate()}
                value={date.toDate()}
                onChange={(date: Date | null) =>
                  setDate(dayjs(date ?? undefined))
                }
                placeholderText={t(
                  "desktop.manage.room_booking.form.date.placeholder",
                )}
              />
            </UncontrolledField>

            <Field
              control={control}
              name="time"
              className="field-width-50"
              label={t("desktop.manage.room_booking.form.time.label")}
            >
              {(props) => (
                <TimeRangePicker
                  placeholder={t(
                    "desktop.manage.room_booking.form.time.placeholder",
                  )}
                  {...props}
                  showAllDay
                  isAllDay={isAllDay}
                  onAllDayChange={setIsAllDay}
                  showAllDayLabel={t(
                    "desktop.manage.room_booking.form.time.all_day",
                  )}
                />
              )}
            </Field>

            <Field
              control={control}
              name="tz"
              label={t("desktop.manage.room_booking.form.timezone.label")}
              className="field-width-50"
            >
              {(props) => (
                <Select
                  {...props}
                  options={TIMEZONE_OPTIONS}
                  clearable
                  nothingFoundMessage={t("general.not_found.no_options_found")}
                  placeholder={t(
                    "desktop.manage.room_booking.form.timezone.placeholder",
                  )}
                />
              )}
            </Field>

            {!reservation && (
              <Field
                control={control}
                name="recurring"
                label={t("desktop.manage.room_booking.form.schedule.label")}
                className="field-width-50"
              >
                {(props) => (
                  <Select
                    {...props}
                    options={SCHEDULE_OPTIONS}
                    nothingFoundMessage={t(
                      "general.not_found.no_options_found",
                    )}
                    placeholder={t(
                      "desktop.manage.room_booking.form.schedule.placeholder",
                    )}
                  />
                )}
              </Field>
            )}

            {!reservation && watch("recurring")?.value !== undefined && (
              <UncontrolledField
                className="field-width-50"
                label={t(
                  "desktop.manage.room_booking.form.schedule.until.label",
                )}
              >
                <DatePicker
                  value={recurringUntil.toDate()}
                  minDate={dayjs().toDate()}
                  onChange={(date: Date | null) =>
                    setRecurringUntil(dayjs(date ?? undefined))
                  }
                  placeholderText={t(
                    "desktop.manage.room_booking.form.schedule.until.placeholder",
                  )}
                />
              </UncontrolledField>
            )}
            <Field
              control={control}
              name="attendees"
              label={t("desktop.manage.room_booking.form.attendees.label")}
              className="field-width-100"
            >
              {(props) => (
                <Select
                  creatable
                  {...props}
                  options={ATTENDEES_OPTIONS}
                  isMulti
                  clearable
                  placeholder={t(
                    "desktop.manage.room_booking.form.attendees.placeholder",
                  )}
                  nothingFoundMessage={t(
                    "desktop.manage.room_booking.form.attendees.no_users",
                  )}
                  formatCreateLabel={(inputValue) =>
                    t("desktop.manage.room_booking.form.attendees.create", {
                      email: inputValue,
                    })
                  }
                  validateCreateValue={validateEmail}
                />
              )}
            </Field>
            {!reservation && (
              <Field
                control={control}
                name="conference_link_id"
                label={t(
                  "desktop.manage.room_booking.form.conference_link_id.label",
                )}
                className="field-width-100"
              >
                {({ ...props }) => (
                  <Input
                    {...props}
                    placeholder={t(
                      "desktop.manage.room_booking.form.conference_link_id.placeholder",
                    )}
                    disabled={true}
                  />
                )}
              </Field>
            )}
            <Field
              control={control}
              name="description"
              label={t("desktop.manage.room_booking.form.description.label")}
              className="field-width-100"
            >
              {({ ...props }) => (
                <Input
                  multiline
                  {...props}
                  placeholder={t(
                    "desktop.manage.room_booking.form.description.placeholder",
                  )}
                />
              )}
            </Field>
            <Field
              control={control}
              name="visibility"
              className="field-width-100"
            >
              {(props) => (
                <Checkbox
                  {...props}
                  isSecondary
                  label={t("desktop.manage.room_booking.form.visibility.label")}
                  value={watch("visibility") === ReservationVisibility.PRIVATE}
                  onChange={(value) =>
                    props.onChange(
                      value
                        ? ReservationVisibility.PRIVATE
                        : ReservationVisibility.PUBLIC,
                    )
                  }
                />
              )}
            </Field>
            <Field
              control={control}
              name="send_notification_to_attendees"
              className="field-width-100"
            >
              {(props) => (
                <Checkbox
                  {...props}
                  isSecondary
                  label={t(
                    "desktop.manage.room_booking.form.send_notification_to_attendees.label",
                  )}
                />
              )}
            </Field>
            <Field
              control={control}
              name="send_notification_to_organizer"
              className="field-width-100"
            >
              {(props) => (
                <Checkbox
                  {...props}
                  isSecondary
                  label={t(
                    "desktop.manage.room_booking.form.send_notification_to_organizer.label",
                  )}
                />
              )}
            </Field>
          </PageForm>
        </FormProvider>
      )}
    </>
  )
}

export default RoomReservationForm
