import React from "react"

import { useTranslation } from "react-i18next"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import RoomSettingsForm from "../../../../components/Form/RoomSettingsForm"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

const RoomSettings = () => {
  const { t } = useTranslation()

  return (
    <View className="SettingsRoomsRoomSettings">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.room_settings.title"),
        ]}
      />

      <Intro>{t("desktop.settings.rooms.room_settings.intro")}</Intro>

      <Space size={0.75} />

      <RoomSettingsForm />
    </View>
  )
}

export default RoomSettings
