import React, { ChangeEvent, useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import { CalendarView } from "../index"

import { useAppSelector } from "../../../../redux/reducers"
import { selectUser } from "../../../../redux/user/selectors"

import Switch from "../../../../components/basic/Switch"
import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import FiltersBar from "../../../../components/Filter/FiltersBar"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import MultiFloorFilter from "../../../../components/Filter/MultiFloorFilter"
import MultiRoomAmenitiesFilter from "../../../../components/Filter/MultiRoomAmenitiesFilter"
import MultiRoomFilter from "../../../../components/Filter/MultiRoomFilter"

import CalendarViewDaySVG from "../../../../assets/images/icons/CalendarViewDay.svg"
import CalendarViewWeekSVG from "../../../../assets/images/icons/CalendarViewWeek.svg"
import UnfoldMoreSVG from "../../../../assets/images/icons/UnfoldMore.svg"

import "./CalendarToolbar.sass"

type Props = {
  calendarView: CalendarView
  onCalendarViewChange: (view: CalendarView) => void
  defaultFilterValues: Filter
  onFilterChange: (filter: Filter) => void
}

export type Filter = {
  building_id: string
  floor_id: string[]
  amenity_id: string[]
  room_id: string[]
  people: string
  search: string
}

export type FilterKeys = keyof Filter

const CalendarToolbar = ({
  calendarView,
  onCalendarViewChange,
  defaultFilterValues,
  onFilterChange,
}: Props) => {
  const { entry: user } = useAppSelector(selectUser)

  const filtersRef = useRef<Filter>(defaultFilterValues)
  const [filters, setFilters] = useState<Filter>(defaultFilterValues)

  const [isMyMeetingsOnly, setMyMeetingsOnly] = useState<boolean>(
    Boolean(filters.people),
  )

  const { t } = useTranslation()

  const updateFilters = useCallback(
    (newFilters: Partial<Filter>) => {
      filtersRef.current = { ...filtersRef.current, ...newFilters }
      onFilterChange(filtersRef.current as Filter)
      setFilters(filtersRef.current)
    },
    [onFilterChange],
  )

  const handleChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[]) => {
      updateFilters({ [filterName]: value })
    },
    [updateFilters],
  )

  const handleChangePeople = useCallback(
    (value: boolean) => {
      setMyMeetingsOnly(value)
      updateFilters({ people: value ? user.email : "" })
    },
    [updateFilters],
  )

  const handleChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateFilters({ search: e.target.value })
    },
    [updateFilters],
  )

  const handleChangeCalendarView = () =>
    onCalendarViewChange(
      calendarView === CalendarView.Day ? CalendarView.Week : CalendarView.Day,
    )

  const floors =
    typeof filters.floor_id === "string" ? [filters.floor_id] : filters.floor_id

  const amenities =
    typeof filters.amenity_id === "string"
      ? [filters.amenity_id]
      : filters.amenity_id

  const rooms =
    typeof filters.room_id === "string" ? [filters.room_id] : filters.room_id

  return (
    <FiltersBar className="CalendarToolbar">
      <div className="ToolbarViewSwitcher" onClick={handleChangeCalendarView}>
        <CalendarViewIcon calendarView={calendarView} />
        <UnfoldMoreSVG />
      </div>

      <BuildingFilter
        value={filters.building_id ?? ""}
        onChange={handleChange("building_id")}
        showAll
      />

      <MultiFloorFilter
        values={floors}
        onChange={handleChange("floor_id")}
        buildingId={filters.building_id}
        optionsName={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.multiple_floors",
        ).toLowerCase()}
        defaultLabel={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.all_floors",
        )}
        showAll
      />

      <MultiRoomAmenitiesFilter
        preSelectedValues={amenities}
        onChange={handleChange("amenity_id")}
        optionsName={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.multiple_amenities",
        ).toLowerCase()}
        defaultLabel={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.all_amenities",
        )}
      />

      <MultiRoomFilter
        values={rooms}
        onChange={handleChange("room_id")}
        buildingId={filters.building_id}
        optionsName={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.multiple_rooms",
        ).toLowerCase()}
        defaultLabel={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.all_rooms",
        )}
        searchValue={filters.search}
        onChangeSearchValue={handleChangeSearch}
        showAll
      />

      <FilterSpace />

      <Switch
        label={t(
          "desktop.manage.room_booking.calendar.toolbar.filters.my_meetings_only",
        )}
        onChange={handleChangePeople}
        value={isMyMeetingsOnly}
      />
    </FiltersBar>
  )
}

const CalendarViewIcon = ({ calendarView }: { calendarView: CalendarView }) => {
  switch (calendarView) {
    case CalendarView.Week:
      return <CalendarViewDaySVG />
    case CalendarView.Day:
      return <CalendarViewWeekSVG />
    default:
      return null
  }
}

export default CalendarToolbar
