import { forwardRef, HTMLProps } from "react"

import classNames from "classnames"

import { InternalTime } from "../../../types/sharedTypes"
import Checkbox from "../Checkbox"
import { TimePicker } from "../TimePicker"

import "./style.sass"

export type TimeRange = {
  start?: InternalTime
  end?: InternalTime
}

export type TimeRangePickerProps = {
  onChange?: (value: TimeRange) => void
  value?: TimeRange
  maxTime?: InternalTime
  minTime?: InternalTime
  name?: string
  hasError?: boolean
  showAllDay?: boolean
  showAllDayLabel?: string
  isAllDay?: boolean
  onAllDayChange?: (isAllDay: boolean) => void
} & Omit<HTMLProps<HTMLInputElement>, "onChange" | "value" | "ref">

export const TimeRangePicker = forwardRef<
  HTMLInputElement,
  TimeRangePickerProps
>(
  (
    {
      onChange,
      name,
      className,
      disabled,
      value,
      autoFocus,
      hasError,
      showAllDay,
      showAllDayLabel = "All day",
      isAllDay,
      onAllDayChange,
      ...props
    }: TimeRangePickerProps,
    ref,
  ) => {
    const cn = classNames("TimeRangePicker", className, { disabled })

    const internalOnChange =
      (type: "start" | "end") => (time: InternalTime) => {
        onChange && onChange({ ...value, [type]: time })
      }

    return (
      <div className={cn} id={name}>
        <div className="PickerWrapper">
          <TimePicker
            autoFocus={autoFocus}
            ref={ref}
            {...props}
            value={isAllDay ? "00:00" : value?.start}
            onChange={internalOnChange("start")}
            name={`${name}-start`}
            disabled={disabled || isAllDay}
            hasError={hasError}
          />
          <span>&ndash;</span>
          <TimePicker
            {...props}
            value={isAllDay ? "00:00" : value?.end}
            onChange={internalOnChange("end")}
            name={`${name}-end`}
            disabled={disabled || isAllDay}
            hasError={hasError}
          />
        </div>
        {showAllDay && (
          <Checkbox
            onChange={onAllDayChange}
            value={isAllDay}
            label={showAllDayLabel}
            isSecondary
          />
        )}
      </div>
    )
  },
)
