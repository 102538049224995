import { useEffect, useState } from "react"

export const useHasScrollbar = (
  ref: React.RefObject<HTMLElement>,
  deps: any[] = [],
) => {
  const [hasScrollbar, setHasScrollbar] = useState(false)

  useEffect(() => {
    const checkScrollbar = () => {
      const el = ref.current
      if (!el) return

      const hasVerticalScroll = el.scrollHeight > el.clientHeight
      const hasHorizontalScroll = el.scrollWidth > el.clientWidth

      setHasScrollbar(hasVerticalScroll || hasHorizontalScroll)
    }

    checkScrollbar()
    window.addEventListener("resize", checkScrollbar)

    return () => {
      window.removeEventListener("resize", checkScrollbar)
    }
  }, [ref, ...deps])

  return hasScrollbar
}
