import React from "react"

import cn from "classnames"

import { CalendarView } from "../index"
import DayView from "./DayView/DayView"
import WeekView from "./WeekView/WeekView"

import { RoomReservation } from "../../../../redux/api/roomReservations/types"

import "./CalendarGrid.sass"

type Props = {
  calendarView: CalendarView
  roomsReservation: RoomReservation[]
}

const CalendarGrid = ({ calendarView, roomsReservation }: Props) => {
  const calendarGridClassName = cn(
    "CalendarGrid",
    `CalendarGrid--${calendarView}-view`,
  )

  return (
    <div className={calendarGridClassName}>
      {calendarView === CalendarView.Day && (
        <DayView roomsReservation={roomsReservation} />
      )}

      {calendarView === CalendarView.Week && (
        <WeekView roomsReservation={roomsReservation} />
      )}
    </div>
  )
}

export default CalendarGrid
