import React, { useEffect } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import { isWeekends } from "../../../utils"
import { CalendarView } from "./index"

import { changeDay, toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import Datestring from "../../../components/Datestring"
import Heading from "../../../components/Heading"
import { DayNav, WeekNav } from "../../../components/Manage/CalNav"
import WeekendToggle from "../../../components/Manage/WeekendToggle"

type Props = {
  calendarView: CalendarView
  onNewReservation: () => void
}

const today = dayjs().startOf("day")

const Header = ({ calendarView, onNewReservation }: Props) => {
  const { fromDate, toDate, showWeekends, currentDate } =
    useAppSelector(selectAppDates)

  const weekEnd = showWeekends ? toDate : toDate.subtract(2, "day")

  const actions = useActions({
    changeDay: (day: Dayjs) => changeDay(day.toISOString()),
    toggleWeekends: () => toggleWeekends(),
  })

  const { t } = useTranslation()

  useEffect(() => {
    const shouldResetWeekDay =
      calendarView === "day" && !showWeekends && isWeekends(currentDate)

    if (shouldResetWeekDay) {
      actions.changeDay(today)
    }
  }, [currentDate, calendarView, showWeekends])

  return (
    <Heading>
      {calendarView === CalendarView.Day && (
        <>
          <DayNav closeOnDayClick />
          <span className="date-range">
            <Datestring date={currentDate} isLong isInline />
          </span>
          <WeekendToggle
            showWeekends={showWeekends}
            onToggle={actions.toggleWeekends}
          />
        </>
      )}

      {calendarView === CalendarView.Week && (
        <>
          <WeekNav />
          <span className="date-range">
            <Datestring date={fromDate} isInline isLong /> -{" "}
            <Datestring date={weekEnd} isInline isLong />
          </span>
          <WeekendToggle
            showWeekends={showWeekends}
            onToggle={actions.toggleWeekends}
          />
        </>
      )}

      <div className="spacer" />

      <Button
        className="NewReservation"
        onClick={() => onNewReservation()}
        isSmall
      >
        {t("desktop.manage.room_booking.buttons.new_reservation")}
      </Button>
    </Heading>
  )
}

export default Header
