import { IdAndName } from "../../../../../types/sharedTypes"
import { skipToken } from "@reduxjs/toolkit/query/react"

import { useFetchBuildingQuery } from "../../../../../redux/api/buildings"

import LocationPinOutlineSVG from "../../../../../assets/images/icons/LocationPinOutline.svg"

type Props = {
  building: IdAndName | null
  floor?: IdAndName | null
  tz: string | null
  message?: string
}

const Location = ({ building, floor, tz, message }: Props) => {
  const buildingId = building?.id || ""
  const { data: buildingDetails } = useFetchBuildingQuery(
    buildingId ? { id: buildingId } : skipToken,
  )
  const city = buildingDetails?.address
    ? buildingDetails.address.split(",").slice(1, 2).join(", ")
    : ""

  const locationParts = [city, building?.name, floor?.name].filter(Boolean)

  return (
    <div className="description-row">
      <div className="description-row-icon">
        <LocationPinOutlineSVG />
      </div>

      <div className="description-row-text">
        {locationParts.join(", ")}
        {message && ` ${message}`}
      </div>
    </div>
  )
}

export default Location
