import { forwardRef } from "react"

import dayjs, { Dayjs } from "dayjs"

import { DatePicker, DatePickerProps } from "../DatePicker"

export const DEFAULT_WEEK_PICKER_PORTAL_ID = "WeekPickerPortal"
const DATE_PICKER_SELECTED_CLASS_NAME = "react-datepicker__day--selected"

export type Week = { start: Dayjs; end: Dayjs }
type WeekPickerProps = Omit<DatePickerProps, "onChange" | "value"> & {
  onChange: (week: Week | null) => void
  value: Week | null
  includeWeekends: boolean
}

export const WeekPicker = forwardRef<HTMLInputElement, WeekPickerProps>(
  (
    {
      onChange,
      value,
      filterDate,
      includeWeekends = true,
      ...props
    }: WeekPickerProps,
    ref,
  ) => {
    const innerOnChange = (d: Date | null) => {
      if (d === null) {
        onChange(null)
        return
      }
      const date = dayjs(d)
      onChange({
        start: date.weekday(1).startOf("day"),
        end: date.weekday(includeWeekends ? 7 : 5).endOf("day"),
      })
    }

    const setDayClassName = (date: Date) => {
      if (!includeWeekends) {
        return isSameWeek(date, value) && isWeekday(date)
          ? DATE_PICKER_SELECTED_CLASS_NAME
          : ""
      }
      return isSameWeek(date, value) ? DATE_PICKER_SELECTED_CLASS_NAME : ""
    }

    const internalFilterDate = (date: Date) => {
      return includeWeekends
        ? (filterDate?.(date) ?? true)
        : isWeekday(date) && (filterDate?.(date) ?? true)
    }

    return (
      <DatePicker
        {...props}
        ref={ref}
        value={value?.start.toDate() ?? null}
        onChange={innerOnChange}
        portalId={DEFAULT_WEEK_PICKER_PORTAL_ID}
        dayClassName={setDayClassName}
        filterDate={internalFilterDate}
      />
    )
  },
)

const isSameWeek = (date: Date | null, week: Week | null) =>
  week && date
    ? (dayjs(date).isSame(week.start, "day") ||
        dayjs(date).isAfter(week.start, "day")) &&
      (dayjs(date).isBefore(week.end, "day") ||
        dayjs(date).isSame(week.end, "day"))
    : false

export const isWeekday = (date: Date) => {
  const day = dayjs(date).get("day")
  return day !== 0 && day !== 6
}
