import React from "react"

import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { useBookContext } from "../../../../contexts/Mobile/BookContext"
import { useCalculateBookable } from "../../../../hooks/useCalculateBookable"
import { useToast } from "../../../../hooks/useToast"
import { useAnalyticsScreenView } from "../../../../providers/Mobile/FirebaseAnalyticsProvider"
import {
  isRecurringType,
  RecurringType,
  RepeatPickerTypes,
} from "../../../../types/sharedTypes"
import { internalTimeFormat } from "../../../../utils"
import { DESK_PATHS } from "./constants"
import NoDeskFound from "./NoDeskFound"

import { useCreateDeskReservationMutation } from "../../../../redux/api/deskReservations"
import { useFetchDeskQuery } from "../../../../redux/api/desks"
import { useAppSelector } from "../../../../redux/reducers"
import { TimeslotResponse } from "../../../../redux/timeslots/types"
import { selectUser } from "../../../../redux/user/selectors"

import Loader from "../../../../components/basic/Loader"
import { IconMapper } from "../../../../components/CorrectIconMapper"
import { DateTimePicker } from "../../../../components/Mobile/DateTimePicker"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./AdhocDeskConfirm.sass"

const AdhocDeskConfirm = () => {
  useAnalyticsScreenView("Book/Desks/AdhocDeskConfirm")

  const { infoToast, errorToast } = useToast()
  const { t } = useTranslation()
  const {
    date,
    timeslot,
    repeat,
    repeatUntil,
    onDateTimePick,
    onAdhocBooking,
    goToHome,
  } = useBookContext()
  const history = useHistory()
  const [createDeskReservation] = useCreateDeskReservationMutation()
  const location = useLocation<{ desk_id: string }>()
  const deskId = location.state?.desk_id
  const startTime = timeslot?.from
    ? dayjs(`${date.format("YYYY-MM-DD")}T${timeslot.from}`)
    : null
  const endTime = timeslot?.to
    ? dayjs(`${date.format("YYYY-MM-DD")}T${timeslot.to}`)
    : null
  const user = useAppSelector(selectUser)

  // Fetch desk data
  const { data: desk, isLoading: isDeskLoading } = useFetchDeskQuery(deskId, {
    skip: !deskId,
  })

  // Fetch bookable desks
  const { desksBookable, isLoading: isBookableLoading } = useCalculateBookable({
    date: date.toISOString(),
    buildingId: desk?.building_id,
    floorId: desk?.floor_id,
    timeslot: timeslot || undefined,
  })

  // Show desk status
  const deskIsAlreadyReserved = desksBookable[deskId] === false
  const deskStatus = deskIsAlreadyReserved
    ? t("mobile.home.reserved")
    : t("desktop.manage.desk_booking.available")

  const handleClose = () => goToHome()

  const handleDateTimePick = (
    newDate: Dayjs | null,
    newTimeslot: Partial<TimeslotResponse> | null,
    newRepeat: RepeatPickerTypes | null,
    newRepeatUntil: Dayjs | null,
  ) => {
    // Update state in the context
    onDateTimePick(newDate, newTimeslot, newRepeat, newRepeatUntil)
  }

  const handleBookNow = async () => {
    // Set the desk in context
    onAdhocBooking(desk ?? null)

    // Create desk reservation
    try {
      if (!desk) {
        return
      }

      await createDeskReservation({
        tz: desk.tz,
        desk_id: deskId,
        user_email: user.entry.email,
        timeslot_id: timeslot?.id ?? "",
        start: startTime?.toISOString(),
        end: endTime?.toISOString(),
        ...(repeat && isRecurringType(repeat) && repeatUntil
          ? {
              recurring: {
                freq: repeat as RecurringType,
                until: repeatUntil.toISOString(),
              },
            }
          : {}),
      }).unwrap()

      infoToast(t("mobile.desk_booking.success"))

      history.push(DESK_PATHS.home)
    } catch (error) {
      errorToast(t("mobile.desk_booking.error"))
    }
  }

  const isLoading = isDeskLoading || isBookableLoading

  return (
    <SafeViewArea className="AdhocDeskConfirm">
      <div className="head">
        <TopNav onClose={handleClose} />
        <div className="icon">
          <div>
            <IconMapper iconType="desk" needsWrap={false} />
          </div>
        </div>
      </div>

      <div className="body">
        {isLoading && <Loader variant="fullScreen" />}

        {deskId && desk && !isLoading && (
          <>
            <div className="main-data">
              <div className="info">
                <div className="type">{t("mobile.general.desk")}</div>
                <div className="status">{deskStatus}</div>
              </div>
              <div className="name">{desk.name}</div>
              <div className="location">
                {desk.floor.name}, {desk.building.name}
              </div>
            </div>

            <DateTimePicker
              date={date}
              useTodayAsDefaultDate
              timeslot={timeslot}
              repeat={repeat || undefined}
              repeatUntil={repeatUntil}
              preferredTime={
                date.isToday()
                  ? dayjs().format(internalTimeFormat())
                  : dayjs("9:00", internalTimeFormat()).format(
                      internalTimeFormat(),
                    )
              }
              onPick={handleDateTimePick}
              onAdhocBooking={handleBookNow}
            />
          </>
        )}

        {(!deskId || !desk) && !isLoading && (
          <div className="main-data">
            <NoDeskFound />
          </div>
        )}
      </div>
    </SafeViewArea>
  )
}

export default AdhocDeskConfirm
