import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { RoomRequestWithId } from "../redux/api/roomBookingRooms/types"
import { FloorResponse } from "../redux/floors/types"

import RoomForm from "../components/Form/RoomForm"

type Props = {
  open: boolean
  floor: FloorResponse | null
  room?: RoomRequestWithId | null
  roomsToEdit?: number[]
  isEdit?: boolean
}

export default function RoomModal({
  open,
  floor,
  room,
  roomsToEdit,
  isEdit = false,
}: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <RoomForm
        floor={floor}
        room={room}
        roomsToEdit={roomsToEdit}
        isEdit={isEdit}
      />
    </ReactModal>
  )
}
