import React from "react"

import "./styles.sass"

type Props = {
  columns?: number
  rows?: number
  columnsTemplate?: string
  rowsTemplate?: string
  gap?: number
  width?: string
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const Grid = ({
  columns = 2,
  rows,
  columnsTemplate,
  rowsTemplate,
  gap = 1,
  width = "100%",
  children,
  className,
  style,
}: Props) => {
  const gridStyles = {
    "--grid-columns": columns,
    "--grid-rows": rows ?? "auto",
    "--grid-gap": `${gap}rem`,
    width,
    ...style,
  } as React.CSSProperties

  if (columnsTemplate) {
    gridStyles.gridTemplateColumns = columnsTemplate
  }

  if (rowsTemplate) {
    gridStyles.gridTemplateRows = rowsTemplate
  }

  return (
    <div className={`Grid ${className || ""}`} style={gridStyles}>
      {children}
    </div>
  )
}

export default Grid
