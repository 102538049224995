import React, { useEffect, useMemo, useRef, useState } from "react"

import cn from "classnames"
import dayjs from "dayjs"

import { timeZone as defaultTimezone } from "../../../../../dayjs"
import { useHasScrollbar } from "../../../../../hooks/useHasScrollbar"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { ISODate } from "../../../../../types/sharedTypes"
import { userTimeFormat } from "../../../../../utils"
import { ROOM_BOOKING_PATHS } from "../../constants"
import { HOURS_IN_A_DAY, MINUTES_IN_A_DAY, PIXELS_PER_HOUR } from "../constants"
import CurrentTimeIndicator from "../CurrentTimeIndicator"
import RoomOverviewCell from "../RoomOverviewCell"
import ReservationCard from "./ReservationCard"
import { calculateOverlapCount } from "./utils"

import {
  Reservation,
  RoomReservation,
} from "../../../../../redux/api/roomReservations/types"
import { selectAppDates } from "../../../../../redux/app/selectors"
import { useAppSelector } from "../../../../../redux/reducers"

import "./DayView.sass"

export type ReservationPosition = {
  top: number
  left: number
  width: number
  height: number
  zIndex: number
}

type Props = {
  roomsReservation: RoomReservation[]
}

const DayView = ({ roomsReservation }: Props) => {
  const { push } = useNavigation()

  const calendarLayoutContentRef = useRef<HTMLDivElement>(null)
  const calendarLayoutHeaderWrapperRef = useRef<HTMLDivElement>(null)
  const calendarLayoutHeaderRef = useRef<HTMLDivElement>(null)
  const calendarLayoutSidebarRef = useRef<HTMLDivElement>(null)
  const [offset, setOffset] = useState(calculateOffset())

  function calculateOffset() {
    const calendarLayoutContentEl = calendarLayoutContentRef.current
    const now = dayjs()
    const calculation =
      ((now.hour() * 60 + now.minute()) / MINUTES_IN_A_DAY) *
      (HOURS_IN_A_DAY * PIXELS_PER_HOUR)

    if (!calendarLayoutContentEl) {
      return calculation
    }

    const { scrollTop } = calendarLayoutContentEl

    return calculation - scrollTop
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset(calculateOffset())
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  const handleScrollPosition = () => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current

    if (!calendarLayoutContentEl) return

    const {
      scrollLeft,
      scrollTop,
      scrollWidth,
      scrollHeight,
      clientWidth,
      clientHeight,
    } = calendarLayoutContentEl

    setOffset(calculateOffset())

    setScrollbarPositionClassName(
      [
        scrollTop > 0 && "scroll-top-visible",
        scrollTop + clientHeight < scrollHeight && "scroll-bottom-visible",
        scrollLeft > 0 && "scroll-left-visible",
        scrollLeft + clientWidth < scrollWidth && "scroll-right-visible",
      ]
        .filter(Boolean)
        .join(" "),
    )
  }

  const handleScroll = () => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current
    const calendarLayoutHeaderEl = calendarLayoutHeaderWrapperRef.current
    const calendarLayoutSidebarEl = calendarLayoutSidebarRef.current

    if (
      !calendarLayoutContentEl ||
      !calendarLayoutHeaderEl ||
      !calendarLayoutSidebarEl
    ) {
      return
    }

    calendarLayoutHeaderEl.scrollLeft = calendarLayoutContentEl.scrollLeft
    calendarLayoutSidebarEl.scrollTop = calendarLayoutContentEl.scrollTop
  }

  const { currentDate, showWeekends } = useAppSelector(selectAppDates)

  const [scrollbarPositionClassName, setScrollbarPositionClassName] =
    useState<string>("")

  const hasScrollbar = useHasScrollbar(calendarLayoutContentRef, [
    roomsReservation.length,
    showWeekends,
  ])

  const start = useMemo(() => {
    return currentDate
  }, [currentDate])

  const isToday = start.isSame(dayjs(), "day")

  const generateTimeSlots = () => {
    return Array.from({ length: HOURS_IN_A_DAY }, (_, i) => ({
      value: `${i}:00`,
      label: dayjs().hour(i).minute(0).format(userTimeFormat()),
    }))
  }

  const isEventInSlot = (eventStart: ISODate, hour: number) => {
    const startHour = dayjs(eventStart).tz(defaultTimezone).hour()

    return hour === startHour
  }

  const calculateWidthAndLeftPosition = (
    index: number,
    totalCols: number,
    cellWidth: number,
    overlapCount: number,
  ) => {
    const minWidth = 20

    let width = Math.max(minWidth, cellWidth / Math.max(1, overlapCount))

    const overlapFactor = 0.25
    width += width * overlapFactor

    width = Math.min(width, cellWidth)

    const availableWidth = cellWidth - width * overlapFactor
    const left = Math.min(
      (availableWidth / totalCols) * index,
      cellWidth - width,
    )

    return { width, left }
  }

  const calculateHeightAndTop = (
    startTime: string,
    endTime: string,
    timezone: string,
  ) => {
    const start = dayjs(startTime).tz(timezone)
    const end = dayjs(endTime).tz(timezone)
    const durationInMinutes = end.diff(start, "minute")
    return {
      height: (durationInMinutes / 60) * PIXELS_PER_HOUR,
      top: (start.minute() / 60) * PIXELS_PER_HOUR,
    }
  }

  const calculateReservationPositions = (reservations: Reservation[]) => {
    const totalCols = reservations.length
    const cellWidth = 100

    return reservations.map((reservation, index) => {
      const overlapCount = calculateOverlapCount(reservation, reservations)

      const { width, left } = calculateWidthAndLeftPosition(
        index,
        totalCols,
        cellWidth,
        overlapCount,
      )

      const { height, top } = calculateHeightAndTop(
        reservation.start,
        reservation.end,
        defaultTimezone,
      )

      return {
        reservation,
        position: {
          top,
          left,
          height,
          width,
          zIndex: index + 1,
        },
      }
    })
  }

  const timeSlots = generateTimeSlots()

  const calendarLayoutClassName = cn(
    "calendar-day-view-layout",
    {
      "has-scrollbar": hasScrollbar,
    },
    scrollbarPositionClassName,
  )

  useEffect(() => {
    const calendarLayoutContentEl = calendarLayoutContentRef.current

    if (calendarLayoutContentEl) {
      calendarLayoutContentEl.addEventListener("scroll", handleScrollPosition)
    }

    return () => {
      if (calendarLayoutContentEl) {
        calendarLayoutContentEl.removeEventListener(
          "scroll",
          handleScrollPosition,
        )
      }
    }
  }, [])

  useEffect(() => {
    calendarLayoutContentRef.current?.classList.toggle(
      "has-scrollbar",
      hasScrollbar,
    )
  }, [hasScrollbar])

  return (
    <div className={calendarLayoutClassName}>
      <div
        className="calendar-layout-header-wrapper"
        ref={calendarLayoutHeaderWrapperRef}
      >
        <div className="empty-space"></div>
        <div className="calendar-layout-header" ref={calendarLayoutHeaderRef}>
          {roomsReservation.map((room) => (
            <div key={room.id} className="cell cell-overview">
              <RoomOverviewCell
                name={room.name}
                capacity={room.capacity}
                amenities={room.amenities}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="calendar-layout-body">
        {isToday && <CurrentTimeIndicator offset={offset} />}
        <div className="calendar-layout-sidebar" ref={calendarLayoutSidebarRef}>
          {timeSlots.map((slot, index) => (
            <div key={index} className="time">
              <span>{index > 0 && slot.label}</span>
            </div>
          ))}
        </div>

        <div
          className="calendar-layout-content-wrapper"
          ref={calendarLayoutContentRef}
          onScroll={handleScroll}
        >
          <div className="calendar-layout-content">
            {timeSlots.map((_, timeSlotIdx) => (
              <div key={timeSlotIdx} className="row">
                {roomsReservation.map((room, roomIdx) => {
                  const currentReservations =
                    room.schedule.find((schedule) =>
                      start.isSame(dayjs(schedule.day), "day"),
                    )?.reservations || []

                  const reservationsInSlot = currentReservations.filter(
                    (reservation) =>
                      isEventInSlot(reservation.start, timeSlotIdx),
                  )

                  const calculatedReservations =
                    calculateReservationPositions(reservationsInSlot)

                  return (
                    <div key={room.id} className="cell">
                      {calculatedReservations.map(
                        ({ reservation, position }) => (
                          <ReservationCard
                            key={reservation.id}
                            title={reservation.title}
                            startTime={reservation.start}
                            endTime={reservation.end}
                            my={reservation.my}
                            visibility={reservation.visibility}
                            position={position}
                            onClick={() => {
                              push(
                                ROOM_BOOKING_PATHS.edit.replace(
                                  ":id",
                                  reservation.id,
                                ),
                              )
                            }}
                          />
                        ),
                      )}
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DayView
