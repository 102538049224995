import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./FiltersBar.sass"

type Props = {
  className?: any
}

export const FiltersBar = ({
  children,
  className,
  ...rest
}: PropsWithChildren<Props>) => {
  const filtersClassName = classNames({
    FiltersBar: true,
    [className]: !!className,
  })

  return (
    <div className={filtersClassName} {...rest}>
      {children}
    </div>
  )
}

export default FiltersBar
