import React from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../../../../hooks/useToast"

import { useUpdateMeMutation } from "../../../../../redux/api/me"
import { MeResponse } from "../../../../../redux/api/me/types"
import {
  NotificationSetting,
  NotificationSettingsChannel,
  NotificationSettingType,
} from "../../../../../redux/api/notifications/types"
import { isApiResponseError, isRejected } from "../../../../../redux/api/types"
import { isPortalAdmin } from "../../../../../redux/user/utils"

import Grid from "../../../../../components/advanced/Grid"
import Checkbox from "../../../../../components/basic/Checkbox"
import Field from "../../../../../components/Field"
import PageForm from "../../../../../components/Form/PageFormHook"

type Props = {
  user?: MeResponse
}

const notificationTypes: NotificationSettingType[] = [
  "desk",
  "room",
  "visitor",
  "system",
]

const notificationChannels: NotificationSettingsChannel[] = [
  "push", // named "App"
  "email",
  "sms",
  "slack",
  "teams",
]

// Define which channels are available for each notification type
const ENABLED_CHANNELS: Record<
  NotificationSettingType,
  NotificationSettingsChannel[]
> = {
  desk: ["email", "push", "teams"], // SMS and Slack not available
  room: ["email", "push", "teams"], // SMS and Slack not available
  visitor: ["email", "push", "teams", "sms", "slack"], // All channels available
  system: ["email", "push", "teams"], // SMS and Slack not available
}

type FormValues = {
  notifications: Record<
    NotificationSettingType,
    Record<NotificationSettingsChannel, boolean>
  >
}

/**
 * Returns default notification settings for new users:
 * - Email and Push notifications are enabled by default for all notification types
 * - SMS is only enabled for visitor notifications
 * - All other channels (Teams, Slack) are disabled by default
 */
const getFallbackNotificationValues = () => {
  return notificationTypes.reduce(
    (acc, type) => ({
      ...acc,
      [type]: Object.fromEntries(
        notificationChannels.map((channel) => [
          channel,
          type === "visitor" && channel === "sms"
            ? true
            : channel === "push" || channel === "email"
              ? true
              : false,
        ]),
      ),
    }),
    {} as Record<
      NotificationSettingType,
      Record<NotificationSettingsChannel, boolean>
    >,
  )
}

/**
 * Returns notification settings based on user preferences:
 * - For existing users: uses their saved notification preferences
 * - For new users: uses fallback values from getFallbackNotificationValues()
 * - Only channels listed in ENABLED_CHANNELS for each type can be enabled
 * @param user - The user object containing notification preferences
 */
const getDefaultNotificationValues = (user?: MeResponse) => {
  if (!user?.notifications?.length) {
    return getFallbackNotificationValues()
  }

  return notificationTypes.reduce(
    (acc, type) => {
      const notif = user?.notifications?.find((n) => n.type === type) || {
        type,
        channels: [],
      }

      return {
        ...acc,
        [type]: Object.fromEntries(
          notificationChannels.map((channel) => [
            channel,
            ENABLED_CHANNELS[type].includes(channel) &&
              notif.channels.includes(channel),
          ]),
        ),
      }
    },
    {} as Record<
      NotificationSettingType,
      Record<NotificationSettingsChannel, boolean>
    >,
  )
}

const NotificationsForm = ({ user }: Props) => {
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const [saveMe] = useUpdateMeMutation()
  const isAdmin = isPortalAdmin(user ?? { groups: [] })

  // Filter out system notifications for non-admin users
  const visibleNotificationTypes = notificationTypes.filter(
    (type) => type !== "system" || isAdmin,
  )

  const methods = useForm<FormValues>({
    defaultValues: { notifications: getDefaultNotificationValues(user) },
  })

  const { control, watch, setValue } = methods
  const notifications = watch("notifications")

  const toggleNotificationSetting = (
    type: NotificationSettingType,
    channel: NotificationSettingsChannel,
  ) => {
    // Don't allow toggling disabled channels
    if (ENABLED_CHANNELS[type].includes(channel)) {
      setValue(
        `notifications.${type}.${channel}`,
        !notifications[type][channel],
      )
    }
  }

  const onUpdateClick = async () => {
    const updatedNotifications: NotificationSetting[] = Object.entries(
      notifications,
    )
      .filter(([type]) => type !== "system" || isAdmin) // Filter out system notifications for non-admin users
      .map(([type, channelsObj]) => ({
        type: type as NotificationSettingType,
        channels: notificationChannels.filter(
          (channel) =>
            ENABLED_CHANNELS[type as NotificationSettingType].includes(
              channel,
            ) && channelsObj[channel],
        ),
      }))

    const payload = {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
      profile: {
        job_title: user?.profile?.job_title ?? "",
        job_role: user?.profile?.job_role ?? "",
        use_case: user?.profile?.use_case ?? [],
        discovery: user?.profile?.discovery ?? "",
        picture_id: user?.profile?.picture?.id ?? null,
      },
      notifications: updatedNotifications,
    }

    const response = await saveMe(payload)

    if (response && isRejected(response)) {
      if (isApiResponseError(response.error)) {
        errorToast(
          t("desktop.settings.profile.notifications.form.update_error_toast"),
        )
      }
      return
    }

    infoToast(
      t("desktop.settings.profile.notifications.form.update_success_toast"),
    )
  }

  return (
    <FormProvider {...methods}>
      <PageForm
        className="NotificationsForm"
        updateMode
        onUpdate={onUpdateClick}
      >
        <Grid columnsTemplate="300px 1fr 1fr 1fr 1fr 1fr" gap={2} width="728px">
          <div className="item label"></div>
          {notificationChannels.map((channel) => (
            <div key={channel} className="item label">
              {t(
                `desktop.settings.profile.notifications.form.notification_channels.${channel}`,
              )}
            </div>
          ))}
          <hr className="separator" />
          {visibleNotificationTypes.map((type) => (
            <React.Fragment key={type}>
              <div className="item">
                <span className="label">
                  {t(
                    `desktop.settings.profile.notifications.form.notification_types.${type}.title`,
                  )}
                </span>
                <span>
                  {t(
                    `desktop.settings.profile.notifications.form.notification_types.${type}.description`,
                  )}
                </span>
              </div>
              {notificationChannels.map((channel) => (
                <Field
                  key={`${type}-${channel}`}
                  control={control}
                  name={`notifications.${type}.${channel}`}
                >
                  {(props) => (
                    <Checkbox
                      {...props}
                      onChange={() => toggleNotificationSetting(type, channel)}
                      unavailable={!ENABLED_CHANNELS[type].includes(channel)}
                      isSecondary={true}
                    />
                  )}
                </Field>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </PageForm>
    </FormProvider>
  )
}

export default NotificationsForm
