import React, { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import { MultiOptionType } from "../../types/sharedTypes"
import DropdownMulti from "../basic/DropdownMulti"
import { FilterSpecialValues } from "./types"

import { useLazyFetchFloorsQuery } from "../../redux/api/floors"
import { FloorResponse } from "../../redux/floors/types"
import { floorComparator, formatFloorLabel } from "../../redux/floors/utils"

type Props = {
  values: string[]
  onChange: (values: string[]) => void
  buildingId: string
  showOnlyWithPlan?: boolean
  showAll?: boolean
  defaultLabel: string
  optionsName: string
}

export default function MultiFloorFilter({
  values,
  onChange,
  buildingId,
  showOnlyWithPlan = false,
  showAll = false,
  defaultLabel,
  optionsName,
}: Props) {
  const { t } = useTranslation()

  const [fetchFloors, { data: { results: entries = [] } = {}, isLoading }] =
    useLazyFetchFloorsQuery()

  const valuesRef = useRef<string[]>(values)
  const prevFloorIdsRef = useRef<string[]>([])

  useEffect(() => {
    if (
      buildingId !== FilterSpecialValues.EMPTY &&
      buildingId !== FilterSpecialValues.ALL
    ) {
      fetchFloors({
        stats: true,
        building: buildingId,
      })
    }
  }, [buildingId, fetchFloors])

  useEffect(() => {
    if (
      !isLoading &&
      entries.length > 0 &&
      buildingId === entries[0].building_id
    ) {
      if (showAll) {
        const hasFloor = entries.some((f: FloorResponse) =>
          valuesRef.current.includes(f.id),
        )

        if (!hasFloor) {
          if (!arraysEqual(valuesRef.current, [FilterSpecialValues.ALL])) {
            valuesRef.current = [FilterSpecialValues.ALL]
            onChange([FilterSpecialValues.ALL])
          }
        } else {
          if (!arraysEqual(valuesRef.current, valuesRef.current)) {
            onChange(valuesRef.current)
          }
        }
      } else {
        let floors: FloorResponse[] = entries

        if (showOnlyWithPlan) {
          floors = floors.filter((f) => f.image)
        }

        const floorIds: string[] = floors.map((f) => f.id)

        let hasNewOne = false

        if (prevFloorIdsRef.current) {
          for (let fId of floorIds) {
            if (!prevFloorIdsRef.current.includes(fId)) {
              prevFloorIdsRef.current = floorIds
              valuesRef.current = [...valuesRef.current, fId]
              hasNewOne = true
              onChange(valuesRef.current)
            }
          }
        }

        if (!hasNewOne) {
          const validValues = valuesRef.current.filter((value) =>
            floorIds.includes(value),
          )

          if (validValues.length > 0) {
            if (!arraysEqual(valuesRef.current, validValues)) {
              prevFloorIdsRef.current = floorIds
              valuesRef.current = validValues
              onChange(validValues)
            }
          } else {
            if (!arraysEqual(valuesRef.current, [floorIds[0]])) {
              prevFloorIdsRef.current = floorIds
              valuesRef.current = [floorIds[0]]
              onChange([floorIds[0]])
            }
          }
        }
      }
    } else if (!isLoading && entries.length === 0) {
      if (!arraysEqual(valuesRef.current, [FilterSpecialValues.ALL])) {
        valuesRef.current = [FilterSpecialValues.ALL]
        onChange([FilterSpecialValues.ALL])
      }
    }
  }, [entries, buildingId, showAll, showOnlyWithPlan, onChange, isLoading])

  let floors = entries.slice(0).sort(floorComparator)

  if (showOnlyWithPlan) {
    floors = floors.filter((f: FloorResponse) => f.image)
  }

  if (
    floors.length === 0 ||
    buildingId === FilterSpecialValues.EMPTY ||
    buildingId === FilterSpecialValues.ALL
  ) {
    return null
  }

  const floorOptions: MultiOptionType<string>[] = floors.map((b) => ({
    value: b.id,
    label: formatFloorLabel(t("mobile.book.floor"), b.name),
    isSingleSelect: false,
  }))

  const count = floors.length

  if (showAll) {
    floorOptions.unshift({
      label: (
        <>
          {t("mobile.general.all_floors")}{" "}
          <span className="count">&middot; {count}</span>
        </>
      ),
      value: FilterSpecialValues.ALL,
      isSingleSelect: true,
    })
  }

  return (
    <DropdownMulti
      className="multi-floor-filter"
      options={floorOptions}
      values={values}
      onChange={(v) => {
        valuesRef.current = v
        onChange(v)
      }}
      optionsName={optionsName}
      defaultLabel={defaultLabel}
    />
  )
}

const arraysEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false
  }

  return true
}
