import React, { useCallback, useEffect, useMemo, useRef } from "react"

import { useHistory } from "react-router-dom"

import { timeZone as defaultTimezone } from "../../../../dayjs"
import { useStoredFilter } from "../../../../hooks/useStoredFilter"
import { updateHistory } from "../../../../utils"
import {
  ROOMS_FILTERS_STORE_NAME,
  ROOMS_SCHEDULE_PATHNAME,
} from "../../constants"
import { CalendarView } from "../index"
import CalendarGrid from "./CalendarGrid"
import CalendarNoData from "./CalendarNoData"
import CalendarToolbar, { Filter } from "./CalendarToolbar"

import { useLazyFetchRoomReservationsQuery } from "../../../../redux/api/roomReservations"
import { selectAppDates } from "../../../../redux/app/selectors"
import { useAppSelector } from "../../../../redux/reducers"
import { selectUser } from "../../../../redux/user/selectors"

import Loader from "../../../../components/basic/Loader"
import { FilterSpecialValues } from "../../../../components/Filter/types"

import "./style.sass"

type Props = {
  calendarView: CalendarView
  onCalendarViewChange: (view: CalendarView) => void
}

const Calendar = ({ calendarView, onCalendarViewChange }: Props) => {
  const history = useHistory()
  const { pathname } = history.location

  const { currentDate, fromDate, toDate, showWeekends } =
    useAppSelector(selectAppDates)

  const weekEnd = useMemo(
    () => (showWeekends ? toDate : toDate.subtract(2, "day")),
    [showWeekends, toDate],
  )

  const start = useMemo(() => {
    return calendarView === CalendarView.Day ? currentDate : fromDate
  }, [calendarView, currentDate, fromDate])

  const end = useMemo(() => {
    return calendarView === CalendarView.Day
      ? currentDate.endOf("day")
      : weekEnd
  }, [calendarView, currentDate, weekEnd])

  const { entry: user } = useAppSelector(selectUser)

  const defaultFilter: Filter = {
    building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
    floor_id: [FilterSpecialValues.ALL],
    amenity_id: [FilterSpecialValues.ALL],
    room_id: [FilterSpecialValues.ALL],
    people: "",
    search: "",
  }

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: ROOMS_FILTERS_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })

  const reqParams = useRef<Filter>(storedFilterValues)

  const [
    fetchRoomReservations,
    { data: { results: roomsReservations = [] } = {}, isSuccess },
  ] = useLazyFetchRoomReservationsQuery()

  const hasRooms = roomsReservations.length > 0

  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      reqParams.current = { ...filter }
      updateHistory(pathname, reqParams.current)
      fetchRoomReservations({
        start: start.toISOString(),
        end: end.toISOString(),
        tz: defaultTimezone,
        ...reqParams.current,
      })
    },
    [pathname, start, end],
  )

  const isFilterApplied = useMemo(() => {
    return JSON.stringify(storedFilterValues) !== JSON.stringify(defaultFilter)
  }, [storedFilterValues, defaultFilter])

  useEffect(() => {
    updateHistory(pathname, reqParams.current)
    fetchRoomReservations({
      start: start.toISOString(),
      end: end.toISOString(),
      tz: defaultTimezone,
      ...reqParams.current,
    })
  }, [start, end])

  useEffect(
    () => () => {
      if (pathname.endsWith(ROOMS_SCHEDULE_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  if (!isSuccess) {
    return <Loader />
  }

  return (
    <div className="Calendar">
      <CalendarToolbar
        calendarView={calendarView}
        onCalendarViewChange={onCalendarViewChange}
        defaultFilterValues={storedFilterValues}
        onFilterChange={handleFilterChange}
      />

      {hasRooms ? (
        <CalendarGrid
          calendarView={calendarView}
          roomsReservation={roomsReservations}
        />
      ) : (
        isSuccess && <CalendarNoData isFilterApplied={isFilterApplied} />
      )}
    </div>
  )
}

export default Calendar
