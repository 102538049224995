import classNames from "classnames"

import "./CurrentTimeIndicator.sass"

type CurrentTimeIndicatorProps = {
  offset: number
}

const CurrentTimeIndicator = ({ offset }: CurrentTimeIndicatorProps) => {
  const cn = classNames("CurrentTimeIndicator")

  return (
    <div
      className={cn}
      style={{
        top: `${offset}px`,
      }}
    />
  )
}

export default CurrentTimeIndicator
