import { useEffect } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT } from "../../constants"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useToast } from "../../hooks/useToast"
import Checkbox from "../basic/Checkbox"
import Field from "../Field"
import BuildingFilter from "../Filter/BuildingFilter"
import RoomFilter from "../Filter/RoomFilter"
import Space from "../Space"
import PageForm from "./PageFormHook"

import { useFetchBuildingsQuery } from "../../redux/api/buildings"
import {
  useFetchRoomsRbQuery,
  useUpdateRoomRbMutation,
} from "../../redux/api/roomBookingRooms"

import "./RoomSettingsForm.sass"

type FormValues = {
  show_meeting_subject: boolean
  show_meeting_organizer: boolean
  show_tentative_meetings: boolean
  cleaning_status: boolean
}

const RoomSettingsForm = () => {
  const { errorToast, infoToast } = useToast()
  const { t } = useTranslation()

  const { value: buildingFilter, onChange: setBuildingFilter } =
    useLocalStorage("room-settings-building-filter", "")
  const { value: roomFilter, onChange: setRoomFilter } = useLocalStorage(
    "room-settings-room-filter",
    "",
  )

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  const { data: { results: rooms = [] } = {} } = useFetchRoomsRbQuery({
    building: buildingFilter,
    limit: FETCH_WITH_NO_LIMIT,
  })

  const [updateRoom] = useUpdateRoomRbMutation()

  const selectedRoom = rooms.find((room) => room.key === roomFilter)
  const selectedBuilding = buildings.find(
    (building) => building.id === buildingFilter,
  )

  const onSaveClick = async (values: FormValues) => {
    if (!selectedRoom || !selectedRoom.id) return
    try {
      await updateRoom({
        id: selectedRoom.id,
        settings: {
          device_show_meeting_subject: values.show_meeting_subject,
          device_show_meeting_organizer: values.show_meeting_organizer,
          device_show_tentative_meetings: values.show_tentative_meetings,
          device_cleaning_status: values.cleaning_status,
        },
      }).unwrap()
      infoToast(
        t("desktop.settings.rooms.room_settings.form.toasts.success", {
          room: selectedRoom.name,
        }),
      )
    } catch (error) {
      errorToast(
        t("desktop.settings.rooms.room_settings.form.toasts.error", {
          room: selectedRoom.name,
        }),
      )
    }
  }

  const methods = useForm<FormValues>({
    defaultValues: {
      show_meeting_subject:
        selectedRoom?.settings_effective.device_show_meeting_subject ?? true,
      show_meeting_organizer:
        selectedRoom?.settings_effective.device_show_meeting_organizer ?? true,
      show_tentative_meetings:
        selectedRoom?.settings_effective.device_show_tentative_meetings ?? true,
      cleaning_status:
        selectedRoom?.settings_effective.device_cleaning_status ?? true,
    },
  })
  const { control, reset } = methods

  useEffect(() => {
    reset({
      show_meeting_subject:
        selectedRoom?.settings_effective.device_show_meeting_subject ?? true,
      show_meeting_organizer:
        selectedRoom?.settings_effective.device_show_meeting_organizer ?? true,
      show_tentative_meetings:
        selectedRoom?.settings_effective.device_show_tentative_meetings ?? true,
      cleaning_status:
        selectedRoom?.settings_effective.device_cleaning_status ?? true,
    })
  }, [selectedRoom])

  return (
    <div className="RoomSettingsForm">
      <div className="RoomSettingsForm__header">
        <div className="RoomSettingsForm__header__filters">
          <BuildingFilter value={buildingFilter} onChange={setBuildingFilter} />
          <RoomFilter
            value={roomFilter}
            onChange={setRoomFilter}
            building={buildingFilter}
          />
        </div>
        <div className="RoomSettingsForm__header__text">
          {selectedRoom ? (
            <Trans
              i18nKey="desktop.settings.rooms.room_settings.form.current_configuration"
              values={{
                room: selectedRoom?.name,
              }}
              components={{
                bold: <strong />,
              }}
            />
          ) : (
            <Trans
              i18nKey="desktop.settings.rooms.room_settings.form.no_rooms_found"
              values={{
                building: selectedBuilding?.name,
              }}
              components={{
                bold: <strong />,
              }}
            />
          )}
        </div>
      </div>
      {selectedBuilding && selectedRoom && (
        <FormProvider {...methods}>
          <PageForm updateMode={true} onUpdate={onSaveClick}>
            <div className="RoomSettingsForm__body">
              <div className="RoomSettingsForm__body__title">
                {t("desktop.settings.rooms.room_settings.form.title")}
              </div>
              <Space size={1} />
              <div className="RoomSettingsForm__body__row">
                <Field
                  control={control}
                  name="show_meeting_subject"
                  className="field-width-50"
                >
                  {(props) => (
                    <>
                      <Checkbox
                        {...props}
                        label={t(
                          "desktop.settings.rooms.room_settings.form.show_meeting_subject",
                        )}
                        isSecondary
                      />
                    </>
                  )}
                </Field>
                <Field
                  control={control}
                  name="show_tentative_meetings"
                  className="field-width-50"
                >
                  {(props) => (
                    <>
                      <Checkbox
                        {...props}
                        label={t(
                          "desktop.settings.rooms.room_settings.form.show_tentative_meetings",
                        )}
                        isSecondary
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="RoomSettingsForm__body__row">
                <Field
                  control={control}
                  name="show_meeting_organizer"
                  className="field-width-50"
                >
                  {(props) => (
                    <>
                      <Checkbox
                        {...props}
                        label={t(
                          "desktop.settings.rooms.room_settings.form.show_meeting_organizer",
                        )}
                        isSecondary
                      />
                    </>
                  )}
                </Field>
                <Field
                  control={control}
                  name="cleaning_status"
                  className="field-width-50"
                >
                  {(props) => (
                    <>
                      <Checkbox
                        {...props}
                        label={t(
                          "desktop.settings.rooms.room_settings.form.show_cleaned_status",
                        )}
                        isSecondary
                      />
                    </>
                  )}
                </Field>
              </div>
            </div>
          </PageForm>
        </FormProvider>
      )}
    </div>
  )
}

export default RoomSettingsForm
