import React, { useCallback, useMemo, useState } from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import Calendar from "./Calendar"
import { ROOM_BOOKING_PATHS } from "./constants"
import Header from "./Header"

import { selectAppDates } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"

import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

export enum CalendarView {
  Day = "day",
  Week = "week",
}

const ManageRooms = () => {
  const history = useHistory()

  const { showWeekends } = useAppSelector(selectAppDates)

  const manageRoomsClassName = classNames("ManageRooms", {
    showWeekends,
  })

  const [calendarView, setCalendarView] = useState<CalendarView>(
    CalendarView.Day,
  )

  const handleNewReservation = () => history.push(ROOM_BOOKING_PATHS.add)

  return (
    <View className={manageRoomsClassName}>
      <Header
        calendarView={calendarView}
        onNewReservation={() => handleNewReservation()}
      />

      <Space size={0.75} />

      <Calendar
        calendarView={calendarView}
        onCalendarViewChange={setCalendarView}
      />
    </View>
  )
}

export default ManageRooms
