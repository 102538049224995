import React from "react"

import { useTranslation } from "react-i18next"

import { IdAndName } from "../../../../types/sharedTypes"

import PeopleSVG from "../../../../assets/images/icons/People.svg"

import "./RoomOverviewCell.sass"

type Props = {
  name: string
  capacity: number | null
  amenities: IdAndName[]
}

const RoomOverviewCell = ({ name, capacity, amenities }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="name">{name}</div>
      {capacity !== null && (
        <div className="capacity">
          <PeopleSVG />
          <span>
            {t("desktop.manage.room_booking.calendar.layout.people", {
              count: capacity,
            })}
          </span>
        </div>
      )}
      {amenities.length > 0 && (
        <div className="amenities">
          {amenities.map((a) => (
            <div className="amenity" key={a.id}>
              {a.name}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default RoomOverviewCell
