import { RoomReservation } from "../../../../../redux/api/roomReservations/types"

import "./WeekView.sass"

type Props = {
  roomsReservation: RoomReservation[]
}

const WeekView = ({ roomsReservation }: Props) => {
  return <div className="WeekView">Weekly view is currently not supported</div>
}

export default WeekView
