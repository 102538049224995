import { api } from ".."
import { roomBookingRoomsURL, roomBookingRoomURL, roomsURL } from "../../../api"
import { PaginatedOptions } from "../../types"
import {
  RoomRequestWithId,
  RoomResponse,
  RoomsFetchRequest,
  RoomsResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const roomBookingRooms = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchRoomsRb: builder.query<
      RoomsResponse & { offset: number },
      PaginatedOptions & RoomsFetchRequest
    >({
      query: ({ floor, building, ...options }) => {
        const params = {
          ...(floor &&
            floor !== FilterSpecialValues.ALL && { floor_id: floor }),
          ...(building &&
            building !== FilterSpecialValues.ALL && { building_id: building }),
          ...options,
        }
        return {
          url: roomBookingRoomsURL({ ...params }),
        }
      },
      providesTags: (result, _error) =>
        result
          ? [
              ...result.results.map(({ key }) => ({
                type: "RbRooms" as const,
                id: key,
              })),
              { type: "RbRooms", id: "LIST" },
            ]
          : [{ type: "RbRooms", id: "LIST" }],
      transformResponse: (response: RoomsResponse, _, arg) => {
        return {
          ...response,
          offset: arg?.offset ?? 0,
        }
      },
    }),
    fetchRoomRb: builder.query<RoomResponse, string>({
      query: (key) => ({
        url: roomBookingRoomURL(key),
      }),
      providesTags: (_result, _error, key) => [{ type: "RbRooms", id: key }],
    }),
    createRoomRb: builder.mutation<RoomResponse, { key: string }>({
      query: (body) => ({
        url: roomsURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "RbRooms", id: "LIST" }],
    }),
    updateRoomRb: builder.mutation<RoomResponse, RoomRequestWithId>({
      query: ({ id, ...body }) => ({
        url: roomBookingRoomURL(id?.toString()),
        method: "PATCH",
        body: {
          id,
          ...body,
        },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "RbRooms", id: "LIST" },
        { type: "RbRooms", id: id },
      ],
    }),
    destroyRoomRb: builder.mutation<void, string>({
      query: (key) => ({
        url: roomBookingRoomURL(key),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error) => [{ type: "RbRooms", id: "LIST" }],
    }),
  }),
})

export const {
  useFetchRoomsRbQuery,
  useLazyFetchRoomsRbQuery,
  useFetchRoomRbQuery,
  useCreateRoomRbMutation,
  useUpdateRoomRbMutation,
  useDestroyRoomRbMutation,
} = roomBookingRooms
