import cn from "classnames"
import dayjs from "dayjs"

import { ISODate } from "../../../../../types/sharedTypes"
import { userTimeFormat } from "../../../../../utils"
import { PIXELS_PER_HOUR } from "../constants"
import { ReservationPosition } from "./DayView"

import { ReservationVisibility } from "../../../../../redux/api/roomReservations/types"

import "./ReservationCard.sass"

type Props = {
  title: string
  startTime: ISODate
  endTime: ISODate
  gridHeight?: number
  onClick: () => void
  my: boolean
  visibility: ReservationVisibility
  position: ReservationPosition
}

const now = dayjs()

enum ReservationType {
  My = "my",
  Private = "private",
  Public = "public",
}

const ReservationCard = ({
  title,
  startTime,
  endTime,
  my,
  visibility,
  onClick,
  position,
}: Props) => {
  const isOverdue = dayjs(endTime).isBefore(now)

  const getReservationType = () => {
    if (visibility === ReservationVisibility.PRIVATE)
      return ReservationType.Private
    if (visibility === ReservationVisibility.PUBLIC)
      return my ? ReservationType.My : ReservationType.Public
  }

  const { height, width, left, top, zIndex } = position

  const reservationClassNames = cn("reservation", getReservationType(), {
    "is-overdue": isOverdue,
    compact: height < PIXELS_PER_HOUR,
    full: height >= PIXELS_PER_HOUR,
  })

  return (
    <div
      className={reservationClassNames}
      onClick={onClick}
      style={{
        top: `${top}px`,
        left: `${left}%`,
        width: `${width}%`,
        height: `${height}px`,
        zIndex,
      }}
    >
      <div className="name">{title}</div>
      <div className="time">
        {dayjs(startTime).format(userTimeFormat())}
        {" - "}
        {dayjs(endTime).format(userTimeFormat())}
      </div>
    </div>
  )
}

export default ReservationCard
