import { t } from "i18next"

import { HasGroups, UserData, UserGroup } from "./types"

export function formatUser(user?: UserData) {
  if (!user) {
    return t("mobile.general.unknown")
  }

  const { id, first_name, last_name, email } = user

  if (!!first_name && String(first_name) !== "") {
    if (!!last_name && String(last_name) !== "") {
      return `${first_name} ${last_name}`
    } else {
      return first_name
    }
  } else if (first_name === null && last_name === null) {
    return t("general.anonymous")
  } else if (email) {
    return email
  } else if (email === null) {
    return t("general.anonymous")
  } else if (id) {
    return t("general.employee_no", { id })
  } else {
    return t("mobile.general.unknown")
  }
}

export function isOfficeManager({ groups = [] }: HasGroups) {
  return groups.includes(UserGroup.OFFICE_MANAGER)
}

export function isPortalAdmin(user?: HasGroups & { admin?: boolean }) {
  return (
    (user?.admin === true || user?.groups?.includes(UserGroup.ADMIN)) ?? false
  )
}

export function isNormalUser({ groups = [] }: HasGroups) {
  return groups.includes(UserGroup.USER)
}

export function usernameComparator(a: UserData, b: UserData) {
  if (!a || !b) {
    return 0
  }

  const aName = formatUser(a)
  const bName = formatUser(b)

  return aName.localeCompare(bName)
}
