import { IdAndName, ISODate } from "../../../types/sharedTypes"
import { PaginatedOptions, PaginatedResponse } from "../../types"
import { PrepaidCode } from "../billing/subscriptions/types"

import { SupportedEventType } from "../../../components/advanced/NotificationCard/NotificationCardGenerator/types"
import { AssetIconNames } from "../../../components/AssetIcon"

export const SYSTEM_EVENTS = {
  DEVICE_BATTERY_LOW: "system_device_battery_low",
  CALENDAR_OUT_OF_SYNC: "system_calendar_out_of_sync",
  PREPAID_CODE_TO_EXPIRE: "system_prepaid_code_to_expire",
  DEVICE_WITHOUT_LICENSE: "system_device_without_license",
  USER_WITHOUT_LICENSE: "system_user_without_license",
  UNPAID_INVOICE: "system_unpaid_invoice",
  TRIAL_EXPIRED: "system_trial_expired",
  MISSING_BILLING_DETAILS: "system_missing_billing_details",
} as const

export const DESK_EVENTS = {
  RESERVATION_NEW: "desk_reservation_new",
  RESERVATION_DELETE: "desk_reservation_delete",
  RESERVATION_CANCELLED: "desk_reservation_canceled",
  RESERVATION_RELEASED: "desk_reservation_released",
  CHECKIN_REMINDER: "desk_checkin_reminder",
} as const

export const ASSET_EVENTS = {
  RESERVATION_NEW: "asset_reservation_new",
  RESERVATION_DELETE: "asset_reservation_delete",
  RESERVATION_CANCELLED: "asset_reservation_canceled",
  RESERVATION_RELEASED: "asset_reservation_released",
  CHECKIN_REMINDER: "asset_checkin_reminder",
} as const

export const ROOM_EVENTS = {
  MEETING_REMINDER: "room_meeting_reminder",
  MEETING_ENDING: "room_meeting_ending",
} as const

export const VISITOR_EVENTS = {
  INVITE_CREATED_HOST: "visitor_invite_created_host",
  INVITE_UPDATED_HOST: "visitor_invite_updated_host",
  INVITE_CANCELLED_HOST: "visitor_invite_canceled_host",
  INVITE_CREATED_VISITOR: "visitor_invite_created_visitor",
  INVITE_UPDATED_VISITOR: "visitor_invite_updated_visitor",
  INVITE_CANCELLED_VISITOR: "visitor_invite_canceled_visitor",
  INVITE_CHECKED_IN_HOST: "visitor_invite_checked_in_host",
  INVITE_CHECKED_IN_VISITOR: "visitor_invite_checked_in_visitor",
  EASY_CHECKIN: "visitor_easy_checkin",
  DOCUMENT_SIGNED: "visitor_document_signed",
  QUESTIONNAIRE_FAILED: "visitor_questionnaire_failed",
  DEVICE_ERROR: "visitor_device_error",
} as const

export type SystemEvent = (typeof SYSTEM_EVENTS)[keyof typeof SYSTEM_EVENTS]
export type DeskEvent = (typeof DESK_EVENTS)[keyof typeof DESK_EVENTS]
export type AssetEvent = (typeof ASSET_EVENTS)[keyof typeof ASSET_EVENTS]
export type RoomEvent = (typeof ROOM_EVENTS)[keyof typeof ROOM_EVENTS]
export type VisitorEvent = (typeof VISITOR_EVENTS)[keyof typeof VISITOR_EVENTS]

export type NotificationType =
  | SystemEvent
  | DeskEvent
  | AssetEvent
  | RoomEvent
  | VisitorEvent

type INVITE_NOTIFICATION_TYPE = {
  invite_id: string
  building: IdAndName | null
  tz: string | null
  end: ISODate | null
  start: ISODate | null
  floor: IdAndName | null
  visitor: {
    id: string
    full_name: string
  }
}

type DESK_NOTIFICATION_TYPE = {
  start: ISODate | null
  end: ISODate | null
  tz: string | null
  user_id: number
  desk: {
    building: IdAndName
    floor: IdAndName
    id: string
    name: string
  }
}

type ASSET_NOTIFICATION_TYPE = {
  start: ISODate | null
  end: ISODate | null
  tz: string | null
  user_id: number
  asset: {
    asset_type: {
      id: string
      name: string
      icon: AssetIconNames
    }
    building: IdAndName | null
    id: string
    name: string
  }
}

export type INVITE_CREATED_HOST = INVITE_NOTIFICATION_TYPE
export type INVITE_UPDATED_HOST = INVITE_NOTIFICATION_TYPE
export type INVITE_CHECKED_IN_HOST = INVITE_NOTIFICATION_TYPE
export type INVITE_CANCELLED_HOST = INVITE_NOTIFICATION_TYPE

export type DESK_RESERVATION_RELEASED = DESK_NOTIFICATION_TYPE

export type ASSET_RESERVATION_RELEASED = ASSET_NOTIFICATION_TYPE

export type SYSTEM_CALENDAR_OUT_OF_SYNC = {
  rooms: IdAndName[]
  sync_older_then: ISODate
}
export type SYSTEM_PREPAID_CODE_TO_EXPIRE = {
  subscription: {
    next_billing_at: ISODate
    id: number
  }
  prepaid_code: PrepaidCode
}

type NotificationPayload =
  | INVITE_CREATED_HOST
  | INVITE_UPDATED_HOST
  | INVITE_CHECKED_IN_HOST
  | INVITE_CANCELLED_HOST
  | ASSET_RESERVATION_RELEASED
  | DESK_RESERVATION_RELEASED
  | SYSTEM_CALENDAR_OUT_OF_SYNC
  | SYSTEM_PREPAID_CODE_TO_EXPIRE

export type Notification = {
  id: string
  type: NotificationType
  seen_at: ISODate | null
  message: NotificationPayload
  created_at: ISODate
  valid_until: ISODate | null
}

export type NotificationsResponse = PaginatedResponse<Notification> & {
  deletedCount: number
}

export type NotificationDetailsResponse = Notification

export type NotificationsOptions = PaginatedOptions & {
  valid?: boolean
  seen?: boolean
  type?: SupportedEventType[]
} & {
  // Internal values for cache purposes
  listId?: string
}

export type MarkNotificationsAsReadRequest = {
  ids: string[]
} & {
  // Internal values for cache purposes
  listId?: string
}

export type NotificationSettingType = "desk" | "room" | "visitor" | "system"
export type NotificationSettingsChannel =
  | "email"
  | "slack"
  | "teams"
  | "push"
  | "sms"

export type NotificationSetting = {
  type: NotificationSettingType
  channels: NotificationSettingsChannel[]
}
