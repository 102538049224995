import React, { MouseEvent, ReactNode, useState } from "react"

import classNames from "classnames"

import CrossSVG from "../../../assets/images/icons/Cross.svg"
import ErrorOutlinedSVG from "../../../assets/images/icons/ErrorOutlined.svg"

import "./style.sass"

export type BasicAlertProps = {
  children: ReactNode
  variant?: "warning" | "error" | "info" | "danger"
  className?: string
  href?: string
  isDisabled?: boolean
  hideCloseButton?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

export const BasicAlert = ({
  children,
  className,
  variant = "warning",
  onClick,
  isDisabled = false,
  href,
  hideCloseButton = true,
}: BasicAlertProps) => {
  const cn = classNames("Alert", variant, className, {
    isLink: !!href,
    isDisabled,
  })

  /**
   * Displays alert banner if the content (children) is provided.
   */
  const [show, setShow] = useState(children)

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (isDisabled) {
      return
    }

    if (href) {
      window.open(href, "_blank")
      return
    }

    if (onClick) {
      onClick(e)
      return
    }
  }

  const handleCloseClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    return setShow(undefined)
  }

  if (show) {
    return (
      <div onClick={handleClick} className={cn}>
        {variant === "error" && (
          <div className="ErrorVariant">
            <>
              <div className="icon">
                <ErrorOutlinedSVG />
              </div>
              <div className="content">{show}</div>
            </>
          </div>
        )}
        {variant !== "error" && show}
        {!hideCloseButton && (
          <button className="CloseButton" onClick={handleCloseClick}>
            <CrossSVG />
          </button>
        )}
      </div>
    )
  } else return null
}
