import React, { ChangeEvent, useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import { MultiOptionType } from "../../types/sharedTypes"
import DropdownMultiSearch from "../advanced/DropdownMultiSearch"
import { FilterSpecialValues } from "./types"

import { useLazyFetchRoomsRbQuery } from "../../redux/api/roomBookingRooms"
import { RoomResponse } from "../../redux/api/roomBookingRooms/types"

type Props = {
  values: string[]
  onChange: (values: string[]) => void
  buildingId: string
  showAll?: boolean
  defaultLabel: string
  optionsName: string
  searchValue: string
  onChangeSearchValue: (e: ChangeEvent<HTMLInputElement>) => void
}

export default function MultiRoomFilter({
  values = [],
  onChange,
  buildingId,
  showAll = false,
  defaultLabel,
  optionsName,
  searchValue = "",
  onChangeSearchValue,
}: Props) {
  const { t } = useTranslation()

  const [fetchRooms, { data: { results: entries = [] } = {}, isLoading }] =
    useLazyFetchRoomsRbQuery()

  const selectedRoomsRef = useRef<string[]>(values || [])
  const prevBuildingIdRef = useRef<string | null>(null)
  const prevRoomIdsRef = useRef<string[]>([])

  useEffect(() => {
    if (
      buildingId &&
      buildingId !== FilterSpecialValues.EMPTY &&
      buildingId !== FilterSpecialValues.ALL &&
      buildingId !== prevBuildingIdRef.current
    ) {
      prevBuildingIdRef.current = buildingId
      fetchRooms({ building: buildingId })
    }
  }, [buildingId, fetchRooms])

  useEffect(() => {
    if (
      buildingId &&
      (buildingId === FilterSpecialValues.ALL ||
        buildingId === FilterSpecialValues.EMPTY) &&
      values[0] !== FilterSpecialValues.ALL
    ) {
      onChange([FilterSpecialValues.ALL])
    }

    if (searchValue) {
      onChangeSearchValue({
        target: { value: "" },
      } as ChangeEvent<HTMLInputElement>)
    }
  }, [buildingId])

  useEffect(() => {
    if (isLoading || !buildingId) return

    if (entries.length > 0 && buildingId === entries[0]?.building?.id) {
      if (showAll) {
        const selectedRooms = selectedRoomsRef.current.filter((id) =>
          entries.some((room: RoomResponse) => room.id === Number(id)),
        )

        if (
          selectedRooms.length === 0 &&
          values[0] !== FilterSpecialValues.ALL
        ) {
          onChange([FilterSpecialValues.ALL])
        } else if (JSON.stringify(values) !== JSON.stringify(selectedRooms)) {
          onChange(selectedRooms)
        }
      } else {
        const roomsKeys = entries.map((r) => r.key)

        if (!prevRoomIdsRef.current.length) {
          prevRoomIdsRef.current = roomsKeys
          selectedRoomsRef.current = [roomsKeys[0]]
          if (values[0] !== roomsKeys[0]) {
            onChange([roomsKeys[0]])
          }
        } else {
          const validSelectedRooms = selectedRoomsRef.current.filter((key) =>
            roomsKeys.includes(key),
          )

          if (
            validSelectedRooms.length > 0 &&
            JSON.stringify(values) !== JSON.stringify(validSelectedRooms)
          ) {
            onChange(validSelectedRooms)
          } else if (values[0] !== roomsKeys[0]) {
            selectedRoomsRef.current = [roomsKeys[0]]
            onChange([roomsKeys[0]])
          }
        }
      }
    } else if (
      !isLoading &&
      entries.length === 0 &&
      values[0] !== FilterSpecialValues.ALL
    ) {
      onChange([FilterSpecialValues.ALL])
    }
  }, [entries, buildingId, showAll, isLoading])

  if (
    !buildingId ||
    entries.length === 0 ||
    buildingId === FilterSpecialValues.EMPTY ||
    buildingId === FilterSpecialValues.ALL
  ) {
    return null
  }

  const roomOptions: MultiOptionType<string>[] = entries.map((r) => ({
    value: r.id.toString(),
    label: r.name,
    isSingleSelect: false,
  }))

  const count = entries.length

  if (showAll) {
    roomOptions.unshift({
      label: (
        <>
          {t("desktop.manage.room_booking.calendar.toolbar.filters.all_rooms")}{" "}
          <span className="count">&middot; {count}</span>
        </>
      ),
      value: FilterSpecialValues.ALL,
      isSingleSelect: true,
    })
  }

  return (
    <DropdownMultiSearch
      className="multi-room-filter"
      options={roomOptions}
      values={values}
      onChange={(v) => {
        selectedRoomsRef.current = v
        onChange(v)
      }}
      searchValue={searchValue}
      onChangeSearchValue={onChangeSearchValue}
      optionsName={optionsName}
      defaultLabel={defaultLabel}
    />
  )
}
