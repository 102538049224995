import { forwardRef } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import ReactDatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

import CalendarSVG from "../../../assets/images/icons/Calendar.svg"
import DaySVG from "../../../assets/images/icons/Day.svg"

import "./style.sass"

export const DEFAULT_DATE_PICKER_PORTAL_ID = "DatePickerPortal"

export type BasicDatePickerProps = {
  maxDate?: Date | string
  minDate?: Date | string
  value: Date | string | null
  onChange: (date: Date | null) => void
  className?: string
  placeholderText?: string
  todayButtonText?: string
  disabled?: boolean
  showYearDropdown?: boolean
  showMonthDropdown?: boolean
  showToday?: boolean
  clearable?: boolean
  hasError?: boolean
  autoComplete?: "on" | "off"
  open?: boolean
  dayClassName?: (date: Date) => string
  portalId?: string
  filterDate?: (date: Date) => boolean
  onClickOutside?: () => void
  withTime?: boolean
  timeIntervals?: number
  locale?: string
  timeFormat?: string
  dateFormat?: string
  timeCaption?: string
  useUtc?: boolean
  highlightDates?: Date[]
}

const convertDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export const BasicDatePicker = forwardRef<
  HTMLInputElement,
  BasicDatePickerProps
>(
  (
    {
      className,
      value,
      minDate,
      maxDate,
      disabled,
      hasError,
      placeholderText = "mm/dd/yyyy",
      todayButtonText = "Select today",
      showToday = true,
      showMonthDropdown = false,
      clearable = false,
      portalId = DEFAULT_DATE_PICKER_PORTAL_ID,
      withTime = false, // We don't want to show time by default
      timeIntervals = 30,
      // dateFormat = withTime ? "MMMM d, yyyy HH:mm" : "MMMM d, yyyy",
      dateFormat = "MMMM d, yyyy",
      timeFormat = "HH:mm",
      useUtc,
      highlightDates,
      ...props
    }: BasicDatePickerProps,
    ref,
  ) => {
    /**
     * React Datepicker uses Date object for value which automatically uses browser's timezone.
     * JOAN-7188: Requires usage of UTC for custom content scheduling.
     *  */
    const date = value
      ? useUtc && value.toString().endsWith("Z")
        ? convertDateToUTC(dayjs(value).toDate())
        : dayjs(value).toDate()
      : null

    const cn = classNames("DatePickerInput", className, {
      disabled,
      error: hasError,
    })

    return (
      <div className={cn}>
        <ReactDatePicker
          {...props}
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          className="date-picker-input"
          isClearable={clearable}
          selected={date}
          showMonthDropdown={showMonthDropdown}
          placeholderText={placeholderText}
          showPopperArrow={false}
          todayButton={showToday && <TodayButton text={todayButtonText} />}
          fixedHeight
          disabled={disabled}
          customInput={<input ref={ref} />}
          portalId={portalId}
          autoComplete="off"
          calendarStartDay={1}
          showTimeSelect={withTime} // For enabling time selection as well
          timeIntervals={timeIntervals} // For changing time intervals
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          highlightDates={highlightDates}
          formatWeekDay={(nameOfDay) => nameOfDay.charAt(0)}
        />
        <CalendarSVG className="calendar-icon" />
      </div>
    )
  },
)

const TodayButton = ({ text }: { text: string }) => (
  <div className="TodayButton">
    <DaySVG />
    <span>{text}</span>
  </div>
)
