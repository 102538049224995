import { ChangeEvent, ForwardedRef, forwardRef, Fragment } from "react"

import classNames from "classnames"
import { t } from "i18next"

import { MultiOptionType } from "../../../types/sharedTypes"
import Search from "../../Search"

import ChevronSVG from "../../../assets/images/icons/Chevron.svg"

import "./style.sass"

export type DropdownMultiProps<T extends string | number> = {
  options: MultiOptionType<T>[]
  values?: T[]
  onChange: (v: T[]) => void
  className?: string
  loading?: boolean
  optionsName: string
  defaultLabel: string
  searchValue: string
  onChangeSearchValue: (e: ChangeEvent<HTMLInputElement>) => void
}

const DropdownMultiSearch = <T extends string | number>(
  {
    options,
    values: currentValues = [],
    onChange,
    className,
    loading,
    optionsName,
    defaultLabel,
    searchValue,
    onChangeSearchValue,
  }: DropdownMultiProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  /**
   * Computed values
   */
  const getCurrentLabel = () => {
    if (currentValues.length > 1) {
      return t("desktop.components.dropdown_multi.multiple_options", {
        optionsName,
      })
    }
    return (
      options.find((opt) => opt.value === currentValues[0])?.label ??
      defaultLabel ??
      ""
    )
  }

  const getOptionsClasses = (value: T) => {
    return classNames("option", { active: currentValues.includes(value) })
  }

  /**
   * Handlers
   */
  const handleOnChange = (value: T, isSingleSelect: boolean = false) => {
    if (currentValues.length === 0) {
      onChange([value])
      return
    }
    if (
      currentValues?.length === 1 &&
      options.find((opt) => opt.value === currentValues?.[0])?.isSingleSelect
    ) {
      onChange([value])
      return
    }
    if (currentValues.find((currentValue) => currentValue === value)) {
      onChange(currentValues.filter((currentValue) => currentValue !== value))
      return
    }
    onChange(isSingleSelect ? [value] : [...currentValues, value])
  }

  /**
   * Render
   */
  const Chevron = options.length !== 1 && <ChevronSVG />

  if (loading) return null

  return (
    <div ref={ref} className={classNames("DropdownMultiSearch", className)}>
      <div className="control">
        <span className="label">{getCurrentLabel()}</span> {Chevron}
      </div>
      {options.length > 1 && (
        <>
          <div className="options">
            {Chevron}
            {options.map(({ label, value, isSingleSelect }, i) => (
              <Fragment key={`option-${i}`}>
                <div
                  className={getOptionsClasses(value)}
                  onClick={() => handleOnChange(value, isSingleSelect)}
                >
                  {!isSingleSelect && <div className="indicator" />}
                  <span className="label">{label}</span>
                </div>

                {/*It's necessary for the correct placement of the search bar*/}
                {i === 0 && (
                  <Search
                    value={searchValue}
                    onChange={onChangeSearchValue}
                    onClear={() =>
                      onChangeSearchValue({
                        target: { value: "" },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default forwardRef(DropdownMultiSearch) as <T extends string | number>(
  props: DropdownMultiProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof DropdownMultiSearch>
