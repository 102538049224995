import React, { MouseEvent } from "react"

import { openInAppBrowser } from "../../../utils"
import { BasicAlert, BasicAlertProps } from "../../basic/Alert"

export type AlertProps = BasicAlertProps

const Alert = ({ isDisabled, href, onClick, ...rest }: AlertProps) => {
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (isDisabled) {
      return
    }

    if (href) {
      openInAppBrowser(href)
      return
    }

    if (onClick) {
      onClick(e)
      return
    }
  }

  return <BasicAlert onClick={handleClick} {...rest}></BasicAlert>
}

export default Alert
