import { MouseEvent, PropsWithChildren, ReactNode } from "react"

import classNames from "classnames"
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "../advanced/Button"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import { useModals } from "@mattjennings/react-modal-stack"

import CrossSVG from "../../assets/images/icons/Cross.svg"

import "./ModalForm.sass"

type Props<T extends FieldValues> = {
  title: string | ReactNode
  className?: string
  updateMode?: boolean
  onCreate?: (values: T) => Promise<void>
  onUpdate?: (values: T) => Promise<void>
  onDelete?: (e: MouseEvent) => Promise<void>
  onClose?: (e: MouseEvent) => void
  additionalButton?: JSX.Element
  hasConfirmationPrompt?: boolean
  disabled?: boolean
  createButtonText?: string
  updateButtonText?: string
  deleteButtonText?: string
  deleteConfirmationPrompt?: string
  deleteConfirmationHint?: string
}

const ModalForm = <T extends FieldValues>({
  children,
  className,
  title,
  onCreate,
  onUpdate,
  onDelete,
  onClose,
  createButtonText,
  updateButtonText,
  deleteButtonText,
  deleteConfirmationPrompt,
  deleteConfirmationHint,
  updateMode = false,
  additionalButton,
  hasConfirmationPrompt = true,
  disabled = false,
}: PropsWithChildren<Props<T>>) => {
  const { closeModal, openModal } = useModals()
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext()

  const createText = createButtonText ?? t("general.save")
  const updateText = updateButtonText ?? t("general.save")
  const deleteText = deleteButtonText ?? t("general.delete")

  const modalClass = classNames("ModalForm", className)

  const actionsClass = classNames({
    actions: true,
    "actions-new": !updateMode,
    "actions-edit": updateMode,
  })

  const onSubmit: SubmitHandler<T> = async (values: T) => {
    if (updateMode) {
      await onUpdate?.(values)
    } else {
      await onCreate?.(values)
    }
  }

  const isDisabled = disabled || isSubmitting

  const handleOnClose = (e: MouseEvent) => {
    onClose?.(e)
    closeModal()
  }

  const handleDeleteConfirmation = (e: MouseEvent) => {
    openModal(ConfirmationModal, {
      title: deleteConfirmationPrompt,
      hint: deleteConfirmationHint,
      onConfirm: async () => {
        await onDelete?.(e)
        closeModal()
      },
    })
  }

  return (
    <form
      className={modalClass}
      onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
    >
      <div className="title">
        <h1>{title}</h1>
      </div>
      <div className="close" onClick={handleOnClose}>
        <CrossSVG />
      </div>

      <div className="fields">{children}</div>

      <div className={actionsClass}>
        <Button
          isSubmit
          variant="submit"
          isDisabled={isDisabled}
          isLoading={isSubmitting}
          isVisible={!!onUpdate || !!onCreate}
        >
          {updateMode ? updateText : createText}
        </Button>

        <Button
          className="delete"
          variant="danger-pop"
          onClick={hasConfirmationPrompt ? handleDeleteConfirmation : onDelete}
          isDisabled={isDisabled}
          isLoading={isSubmitting}
          isVisible={updateMode && onDelete !== undefined}
          noConfirm={true}
        >
          {deleteText}
        </Button>
        <span className={"additional"}>{additionalButton}</span>
      </div>
    </form>
  )
}

export default ModalForm
