import { IdAndName, ISODate } from "../../../types/sharedTypes"
import { Recurring } from "../../types"
import { CalendarType } from "../calendars/types"

type UUID = string

type Room = {
  key: string
  name: string
  id: number
}

type Device = {
  id: UUID
  name?: string
}

type ExternalUser = {
  email: string
  name?: string
}

type User = {
  email: string
  name?: string
}

export type Person = {
  id: UUID
  device?: Device | null
  external?: ExternalUser | null
  user?: User | null
}

type CheckInData = {
  check_in_at?: ISODate | null
  check_out_at?: ISODate | null
}

type Calendar = {
  id: string
  type: CalendarType
}

export type Reservation = {
  id: string
  title: string
  start: ISODate
  end: ISODate
  tz: string
  visibility: ReservationVisibility
  my: boolean
}

type RoomSchedule = {
  day: ISODate
  reservations: Reservation[]
}

export enum ReservationVisibility {
  PRIVATE = "private",
  PUBLIC = "public",
}

export type RoomReservationResponse = {
  id: UUID
  title?: string
  start: ISODate
  end: ISODate
  tz: string
  is_all_day: boolean
  room: Room
  organizer?: Person | null
  creator?: Person | null
  attendees?: Person[] | null
  conference_link_id?: string | null
  description?: string | null
  visibility: ReservationVisibility
  my: boolean
  checkin_at?: CheckInData | null
  send_notification_to_attendees: boolean
  send_notification_to_organizer: boolean
}

export type RoomReservationRequest = {
  title: string
  room_id: number
  start: ISODate
  end: ISODate
  tz: string
  organizer_email: string
  is_all_day: boolean
  visibility: ReservationVisibility
  attendees: string[]
  description?: string | null
  conference_link_id?: string | null
  send_notification_to_attendees: boolean
  send_notification_to_organizer: boolean
  recurring?: Omit<Recurring, "id"> | null
}

export type RoomReservationEditRequest = RoomReservationRequest & {
  id: string
}

export type RoomReservation = {
  id: number
  key: string
  name: string
  calendar: Calendar
  capacity: number | null
  company: string
  amenities: IdAndName[]
  schedule: RoomSchedule[]
}

export type FetchRoomReservationsOptions = {
  start: ISODate
  end: ISODate
  tz: string
  building_id?: string
  floor_id?: string[]
  amenity_id?: string[]
  room_id?: string[]
  people?: string
}
