import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import classNames from "classnames"
import Draggable, { DraggableData, DraggableEvent } from "react-draggable"

import { useLongPress } from "../hooks/useLongPress"
import { IdAndName, ScheduleReservation } from "../types/sharedTypes"
import PlaceTooltip from "./PlaceTooltip"

import { DepartmentResponse } from "../redux/api/departments/types"
import { selectIsMobile } from "../redux/app/selectors"
import { useAppSelector } from "../redux/reducers"

import ChairSVG from "../assets/images/icons/Chair.svg"
import RoomSVG from "../assets/images/icons/Room.svg"

import "./Place.sass"

type Props = {
  id?: string
  x: number
  y: number
  title: string
  amenities?: IdAndName[]
  departments?: DepartmentResponse[]
  reservations?: ScheduleReservation[]
  mapWidth: number
  mapHeight: number
  scale?: number
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  onToggleReposition?: () => void
  onDownDragPlace?: (id: string | number | undefined) => void
  onStopDragPlace?: (e: DraggableEvent, data: DraggableData) => void
  isInactive?: boolean
  isUnmarked?: boolean
  isBlinking?: boolean
  isAvailable?: boolean
  isOccupied?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isFocused?: boolean
  isCurrentlyBooked?: boolean
  isHovered?: boolean
  isHighlighted?: boolean
  type?: "desk" | "room"
}

export default function Place({
  id,
  x,
  y,
  title,
  amenities,
  departments,
  reservations,
  mapWidth,
  mapHeight,
  scale = 1,
  onClick,
  onMouseOver,
  onMouseOut,
  onToggleReposition,
  onDownDragPlace,
  onStopDragPlace,
  isInactive = false,
  isUnmarked = false,
  isBlinking = false,
  isAvailable = false,
  isOccupied = false,
  isDisabled = false,
  isSelected = false,
  isFocused = false,
  isCurrentlyBooked = false,
  isHovered = false,
  isHighlighted = false,
  type = "desk",
}: Props) {
  const isMobile = useAppSelector(selectIsMobile)

  const placeRef = useRef<HTMLDivElement>(null)

  const { reposition_desk } = useAppSelector((state) => state.app)

  const isRepositionDesk = reposition_desk && reposition_desk.id

  const isDisabledDrag =
    !isSelected || !isRepositionDesk || id !== reposition_desk.id

  const [isOpen, setIsOpen] = useState(false)

  const currentPosition = useMemo(
    () => ({
      x: x / mapWidth,
      y: y / mapHeight,
    }),
    [x, y, mapWidth, mapHeight],
  )

  const placeStyle = useMemo(
    () => ({
      top: `${(y / mapHeight) * 100}%`,
      left: `${(x / mapWidth) * 100}%`,
    }),
    [x, y, mapHeight, mapWidth],
  )
  const uniqueId = `${x}${y}`

  const handleMouseClick = useCallback(() => {
    if (isDisabled) {
      return undefined
    }

    onClick?.()
  }, [onClick, isDisabled])

  const handleLongMouseClick = useCallback(() => {
    if (isDisabled) {
      return undefined
    }

    if (!isSelected) {
      onClick?.()
    }

    onToggleReposition?.()
  }, [isDisabled, isSelected, onToggleReposition, onClick])

  const longPressEvent = useLongPress(
    {
      onLongPress: handleLongMouseClick,
      onClick: handleMouseClick,
    },
    {
      delay: 175,
      shouldPreventDefault: true,
    },
  )

  useEffect(() => {
    if (isHovered || isSelected || isHighlighted) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isHighlighted, isHovered, isSelected])

  const placeClassName = classNames({
    Place: true,
    isInactive,
    isUnmarked,
    isBlinking,
    isFocused,
    isSelected,
    isAvailable,
    isOccupied,
    isDisabled,
    isCurrentlyBooked,
    isHovered,
    isHighlighted,
    isMobile,
    isRoom: type === "room",
  })

  const wrapperClassName = classNames("PlaceWrapper", {
    "show-tooltip": isOpen,
  })

  return (
    <Draggable
      nodeRef={placeRef}
      axis={isDisabledDrag ? "none" : "both"}
      position={currentPosition}
      scale={scale}
      onMouseDown={() => (onDownDragPlace ? onDownDragPlace(id) : undefined)}
      onStop={onStopDragPlace}
      bounds="parent"
    >
      <div ref={placeRef} className={wrapperClassName} style={placeStyle}>
        <PlaceTooltip
          title={title}
          amenities={amenities}
          departments={departments}
          reservations={reservations}
          uniqueId={uniqueId}
        >
          <span
            className={placeClassName}
            id={uniqueId}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            {...longPressEvent}
          >
            {type === "room" ? <RoomSVG /> : <>&nbsp;</>}
          </span>
        </PlaceTooltip>
      </div>
    </Draggable>
  )
}
